export enum AuditableModelType {
  FIELD = 'FIELD',
  SECTION = 'SECTION',
  GROUP = 'GROUP',
  CHALLENGE = 'CHALLENGE',
  TASK = 'TASK',
  USER = 'USER',
  LEVEL = 'LEVEL',
  PART = 'PART',
  SUBTOPIC = 'SUBTOPIC',
  TOPIC = 'TOPIC',
  SCHOOL = 'SCHOOL',
  INSTANCE_CONFIG_VALUE = 'INSTANCE_CONFIG_VALUE',
  RUBRIC_PROGRESS_VALUE = 'RUBRIC_PROGRESS_VALUE',
  PERSPECTIVE = 'PERSPECTIVE',
  COACH_COMMENT = 'COACH_COMMENT',
  ROW = 'ROW',
}

export enum AuditEventType {
  CREATE = 'CREATE',
  MODIFY = 'MODIFY',
  DELETE = 'DELETE',
}

export enum AuditEventSubtype {
  CHALLENGE_SHARE = 'CHALLENGE_SHARE',
  CHALLENGE_LEAVE = 'CHALLENGE_LEAVE',
  CHALLENGE_INVITE_ACCEPT = 'CHALLENGE_INVITE_ACCEPT',
  COACH_RUBRIC_PROGRESS_MOVE = 'COACH_RUBRIC_PROGRESS_MOVE',
  USER_PERSPECTIVE_COHORT_ATTACH = 'USER_PERSPECTIVE_COHORT_ATTACH',
  USER_PERSPECTIVE_COHORT_DETACH = 'USER_PERSPECTIVE_COHORT_DETACH',
  USER_GROUPS_CHANGE = 'USER_GROUPS_CHANGE',
  USER_MANAGED_GROUPS_CHANGE = 'USER_MANAGED_GROUPS_CHANGE',
  PORTFOLIO_TEMPLATE_MOVE = 'PORTFOLIO_TEMPLATE_MOVE',
  FIELD_PART_ADD = 'FIELD_PART_ADD',
  FIELD_PART_REMOVE = 'FIELD_PART_REMOVE',
  CHALLENGE_MODEL_MOVE = 'CHALLENGE_MODEL_MOVE',
  PERSPECTIVE_CHANGE_CATEGORY = 'PERSPECTIVE_CHANGE_CATEGORY',
  CHALLENGE_MOVE = 'CHALLENGE_MOVE',
  TASK_MOVE = 'TASK_MOVE',
  CHALLENGE_NAME_CHANGE = 'CHALLENGE_NAME_CHANGE',
  TASK_NAME_CHANGE = 'TASK_NAME_CHANGE',
  CHALLENGE_IS_PUBLIC_CHANGE = 'CHALLENGE_IS_PUBLIC_CHANGE',
  TASK_IS_PUBLIC_CHANGE = 'TASK_IS_PUBLIC_CHANGE',
  CHALLENGE_FIELD_CHANGE = 'CHALLENGE_FIELD_CHANGE',
  TASK_FIELD_CHANGE = 'TASK_FIELD_CHANGE',
  CHALLENGE_DATE_CHANGE = 'CHALLENGE_DATE_CHANGE',
  TASK_DATE_CHANGE = 'TASK_DATE_CHANGE',
  LOG_ENTRY_CREATE = 'LOG_ENTRY_CREATE',
  LOG_ENTRY_CHANGE = 'LOG_ENTRY_CHANGE',
  LOG_ENTRY_DELETE = 'LOG_ENTRY_DELETE',
  CHALLENGE_PROOF_LINK_ADD = 'CHALLENGE_PROOF_LINK_ADD',
  CHALLENGE_PROOF_LINK_DELETE = 'CHALLENGE_PROOF_LINK_DELETE',
  TASK_PROOF_LINK_ADD = 'TASK_PROOF_LINK_ADD',
  TASK_PROOF_LINK_DELETE = 'TASK_PROOF_LINK_DELETE',
  CHALLENGE_RUBRIC_PROGRESS_SET_STUDENT = 'CHALLENGE_RUBRIC_PROGRESS_SET_STUDENT',
  CHALLENGE_RUBRIC_PROGRESS_SET_COACH = 'CHALLENGE_RUBRIC_PROGRESS_SET_COACH',
  TASK_RUBRIC_PROGRESS_SET_STUDENT = 'TASK_RUBRIC_PROGRESS_SET_STUDENT',
  TASK_RUBRIC_PROGRESS_SET_COACH = 'TASK_RUBRIC_PROGRESS_SET_COACH',
  ROW_MOVE = 'ROW_MOVE',
  TASK_ASSIGNEE_ADD = 'TASK_ASSIGNEE_ADD',
  TASK_ASSIGNEE_REMOVE = 'TASK_ASSIGNEE_REMOVE',
  TASK_PERSPECTIVE_ATTACHMENT_SET = 'TASK_PERSPECTIVE_ATTACHMENT_SET',
}

export enum SecurityAuditEventType {
  LOGIN = 'LOGIN',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  ROLE_CHANGE = 'ROLE_CHANGE',
  ROLE_CHANGE_BULK = 'ROLE_CHANGE_BULK',
  ROLE_ESCALATION_ATTEMPT = 'ROLE_ESCALATION_ATTEMPT',
  USER_CREATE = 'USER_CREATE',
  USER_DELETE = 'USER_DELETE',
  USER_DELETE_BULK = 'USER_DELETE_BULK',
  INSTANCE_CONFIG_CHANGE = 'INSTANCE_CONFIG_CHANGE',
  ACCESS_ALL_USERS = 'ACCESS_ALL_USERS',
  ACCESS_ALL_GROUPS = 'ACCESS_ALL_GROUPS',
  ACCESS_PORTFOLIO_TEMPLATES_EDITOR = 'ACCESS_PORTFOLIO_TEMPLATES_EDITOR',
  ACCESS_CHALLENGE_MODEL_EDITOR = 'ACCESS_CHALLENGE_MODEL_EDITOR',
  ACCESS_AUDIT_EVENTS = 'ACCESS_AUDIT_EVENTS',
  ACCESS_SECURITY_AUDIT_EVENTS = 'ACCESS_SECURITY_AUDIT_EVENTS',
  COACH_VIEW_USER = 'COACH_VIEW_USER',
  SUSPICIOUS_REQUEST = 'SUSPICIOUS_REQUEST',
  SUSPICIOUS_VALUE_SUBMITTED = 'SUSPICIOUS_VALUE_SUBMITTED',
}

export enum UserRole {
  STUDENT = 'STUDENT',
  COACH = 'COACH',
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}
