import styled from '@emotion/styled';

type LoadingContentWrapperProps = {
  noPadding: boolean;
};

const LoadingContentWrapper = styled('section')<LoadingContentWrapperProps>(
  ({ noPadding }) => ({
    paddingTop: noPadding ? undefined : 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

export default LoadingContentWrapper;
