import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const PageContentGrid = styled('section')({
  marginTop: 48,
  display: 'flex',
  alignItems: 'flex-start',
  [mediaQuery(1600)]: {
    marginTop: 32,
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default PageContentGrid;
