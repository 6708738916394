import { LineChart } from '@mui/x-charts';
import GraphWrapper from './GraphWrapper';
import moment from 'moment';

type InnerAuditEventVolumesGraphProps = {
  auditEventStats: {
    label: string;
    data: Record<string, number>;
  };
  securityAuditEventStats: {
    label: string;
    data: Record<string, number>;
  };
};

const InnerAuditEventVolumesGraph = ({
  auditEventStats,
  securityAuditEventStats,
}: InnerAuditEventVolumesGraphProps) => {
  const dates = Object.keys(auditEventStats.data).map(date => new Date(date));

  return (
    <GraphWrapper>
      <h4>Event volumes (last 30 days)</h4>
      <LineChart
        xAxis={[
          {
            data: dates,
            scaleType: 'time',
            valueFormatter: date => moment(date).format('L'),
          },
        ]}
        yAxis={[
          {
            tickMinStep: 1,
          },
        ]}
        series={[
          {
            id: 'audit-events',
            label: auditEventStats.label,
            curve: 'linear',
            data: Object.values(auditEventStats.data),
            showMark: false,
            area: true,
          },
          {
            id: 'security-events',
            label: securityAuditEventStats.label,
            curve: 'linear',
            data: Object.values(securityAuditEventStats.data),
            showMark: false,
          },
        ]}
        slots={{
          legend: () => null,
        }}
        margin={{
          top: 20,
          bottom: 30,
          left: 42,
          right: 10,
        }}
        height={200}
      />
    </GraphWrapper>
  );
};

export default InnerAuditEventVolumesGraph;
