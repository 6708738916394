import React from 'react';
import styled from '@emotion/styled';
import { darken, lighten } from '@mui/material';
import { Theme } from '@emotion/react';

type PerspectiveCardColorFillProps = {
  color: string;
  small?: boolean;
  children?: React.ReactNode;
};

type StyledFillProps = {
  color: string;
  small: boolean;
};

const darkenOrLighten = (color: string, theme: Theme): string => {
  const contrastColor = theme.palette.getContrastText(color);

  if (contrastColor === '#fff') {
    return lighten(color, 0.3);
  }

  return darken(color, 0.2);
};

const StyledFill = styled('footer')<StyledFillProps>(({
  theme,
  color,
  small,
}) => {
  const hoverColor = darkenOrLighten(color, theme);

  return {
    position: 'absolute',
    bottom: 0,
    paddingLeft: 12,
    paddingRight: 12,
    width: '100%',
    height: small ? '10%' : '40%',
    boxSizing: 'border-box',
    backgroundColor: color,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    transition: theme.transitions.create('background-color'),
    zIndex: 0,
    '.perspective-card-color-fill__content > *': {
      color: theme.palette.getContrastText(hoverColor),
      transition: theme.transitions.create('transform'),
      transform: 'scale(0)',
    },
    ':hover, :focus-within': {
      backgroundColor: hoverColor,
      '.perspective-card-color-fill__content > *': {
        transform: 'scale(1)',
      },
    },
  };
});

const PerspectiveCardColorFill = ({
  color,
  small,
  children,
  ...props
}: PerspectiveCardColorFillProps) => (
  <StyledFill color={color} small={small ?? false} {...props}>
    <div className="perspective-card-color-fill__content">{children}</div>
  </StyledFill>
);

export default PerspectiveCardColorFill;
