import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { AdminAuditableModelType, AdminAuditEventSubtype } from '../enums';
import { AuditEventType } from '@/routes/Audit/enums';
import Space from '@/components/Space';
import React, { useState } from 'react';
import { AdminAuditEventsSearchInput } from '../types';
import { Moment } from 'moment';
import SearchForm, {
  SearchFormGroup,
  SearchFormGroupTitle,
  SearchFormGroupContent,
  SearchFormFieldWrapper,
  SearchButton,
} from '@/routes/Audit/components/SearchForm';
import SearchFormDateRange from '@/routes/Audit/components/SearchForm/SearchFormDateRange';
import {
  isDateRangeValid,
  toEndOfDay,
} from '@/routes/Audit/components/SearchForm/SearchFormDateRange/dateRangeUtils';
import ModelIdField from '@/routes/Audit/components/SearchForm/ModelIdField';
import useIdState from '@/routes/Audit/components/SearchForm/useIdState';
import NoneMenuItem from '@/routes/Audit/components/SearchForm/NoneMenuItem';

type AdminAuditEventsSearchFormProps = {
  lastSearchInput: AdminAuditEventsSearchInput;
  onSearch: (searchInput: AdminAuditEventsSearchInput) => void;
};

const AdminAuditEventsSearchForm = ({
  lastSearchInput,
  onSearch,
}: AdminAuditEventsSearchFormProps) => {
  const [eventType, setEventType] = useState<AuditEventType | ''>('');
  const [modelType, setModelType] = useState<AdminAuditableModelType | ''>('');
  const [modelId, handleModelIdChange] = useIdState();
  const [modelName, setModelName] = useState('');
  const [eventSubtype, setEventSubtype] = useState<AdminAuditEventSubtype | ''>(
    '',
  );
  const [attribute, setAttribute] = useState('');
  const [oldValue, setOldValue] = useState('');
  const [newValue, setNewValue] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [superUserNameOrEmail, setSuperUserNameOrEmail] = useState('');
  const [originIp, setOriginIp] = useState('');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const handleSearch = (event: React.FormEvent): void => {
    event.preventDefault();

    const searchInput = {
      eventType: eventType || undefined,
      modelType: modelType || undefined,
      modelId: modelId || undefined,
      modelName: modelName.trim() || undefined,
      eventSubtype: eventSubtype || undefined,
      attribute: attribute.trim() || undefined,
      oldValue: oldValue.trim() || undefined,
      newValue: newValue.trim() || undefined,
      additionalInfo: additionalInfo.trim() || undefined,
      superUserNameOrEmail: superUserNameOrEmail.trim() || undefined,
      originIp: originIp.trim() || undefined,
      startDate: startDate?.toDate(),
      endDate: toEndOfDay(endDate)?.toDate(),
    };

    if (JSON.stringify(lastSearchInput) === JSON.stringify(searchInput)) {
      return;
    }

    onSearch(searchInput);
  };

  return (
    <SearchForm onSubmit={handleSearch}>
      <FormControl variant="filled" size="small" fullWidth>
        <InputLabel id="audit-event-type-select-label">Event type</InputLabel>
        <Select<AuditEventType>
          labelId="audit-event-type-select-label"
          id="audit-event-type-select"
          label="Event type"
          value={eventType}
          onChange={e => setEventType(e.target.value as AuditEventType | '')}
        >
          <NoneMenuItem value="">None</NoneMenuItem>
          {Object.values(AuditEventType).map(auditEventType => (
            <MenuItem value={auditEventType} key={auditEventType}>
              {auditEventType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Space height={12} />
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="model">Model</SearchFormGroupTitle>
        <SearchFormGroupContent>
          <FormControl variant="filled" size="small">
            <InputLabel id="auditable-model-type-select-label">
              Model type
            </InputLabel>
            <Select<AdminAuditableModelType>
              labelId="auditable-model-type-select-label"
              id="auditable-model-type-select"
              label="Model type"
              value={modelType}
              onChange={e =>
                setModelType(e.target.value as AdminAuditableModelType | '')
              }
            >
              <NoneMenuItem value="">None</NoneMenuItem>
              {Object.values(AdminAuditableModelType).map(
                auditableModelType => (
                  <MenuItem value={auditableModelType} key={auditableModelType}>
                    {auditableModelType}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
          <Space height={12} />
          <SearchFormFieldWrapper>
            <ModelIdField
              variant="filled"
              size="small"
              label="Model ID"
              type="number"
              value={modelId === null ? '' : modelId.toString()}
              onChange={handleModelIdChange}
              slotProps={{
                input: {
                  slotProps: {
                    input: {
                      min: 1,
                    },
                  },
                },
              }}
            />
            <Space width={12} />
            <TextField
              variant="filled"
              size="small"
              label="Model name"
              value={modelName}
              onChange={e => setModelName(e.target.value)}
            />
          </SearchFormFieldWrapper>
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="additional-specification">
          Additional specification
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <FormControl variant="filled" size="small" fullWidth>
            <InputLabel id="audit-event-subtype-select-label">
              Event subtype
            </InputLabel>
            <Select<AdminAuditEventSubtype>
              labelId="audit-event-subtype-select-label"
              id="audit-event-subtype-select"
              label="Event subtype"
              value={eventSubtype}
              onChange={e =>
                setEventSubtype(e.target.value as AdminAuditEventSubtype | '')
              }
            >
              <NoneMenuItem value="">None</NoneMenuItem>
              {Object.values(AdminAuditEventSubtype).map(auditEventSubtype => (
                <MenuItem value={auditEventSubtype} key={auditEventSubtype}>
                  {auditEventSubtype}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="filled"
            size="small"
            label="Attribute"
            value={attribute}
            onChange={e => setAttribute(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label="Old value"
            value={oldValue}
            onChange={e => setOldValue(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label="New value"
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label="additionalInfo JSON"
            value={additionalInfo}
            onChange={e => setAdditionalInfo(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="user">User</SearchFormGroupTitle>
        <SearchFormGroupContent>
          <TextField
            variant="filled"
            size="small"
            label="User name or e-mail address"
            value={superUserNameOrEmail}
            onChange={e => setSuperUserNameOrEmail(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label="IP address"
            value={originIp}
            onChange={e => setOriginIp(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="date-range">
          Date range
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <SearchFormDateRange
            startDate={startDate}
            onChangeStartDate={date => setStartDate(date)}
            endDate={endDate}
            onChangeEndDate={date => setEndDate(date)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchButton disabled={!isDateRangeValid(startDate, endDate)} />
    </SearchForm>
  );
};

export default AdminAuditEventsSearchForm;
