import GraphLoadingPlaceholder from './GraphLoadingPlaceholder';
import useApiResponse from '@/lib/useApiResponse';
import InnerAuditEventVolumesGraph from './InnerAuditEventVolumesGraph';

type AuditStatsResponse = {
  stats: {
    auditEvents: Record<string, number>;
    securityAuditEvents: Record<string, number>;
  };
};

const AuditEventVolumesGraph = () => {
  const [loading, auditStats] =
    useApiResponse<AuditStatsResponse>('audit-stats');

  if (loading) {
    return <GraphLoadingPlaceholder />;
  }

  return (
    <InnerAuditEventVolumesGraph
      auditEventStats={{
        label: 'Audit events',
        data: auditStats.stats.auditEvents,
      }}
      securityAuditEventStats={{
        label: 'Security events',
        data: auditStats.stats.securityAuditEvents,
      }}
    />
  );
};

export default AuditEventVolumesGraph;
