import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const CountTextWrapper = styled('p')(({ theme }) => ({
  marginTop: 0,
  marginBottom: 12,
  color: theme.palette.text.secondary,
  [mediaQuery(1600)]: {
    marginBottom: 18,
    textAlign: 'center',
  },
}));

export default CountTextWrapper;
