import { useState } from 'react';
import { TableCell, Tooltip } from '@mui/material';
import { AdminAuditEvent, AdminSecurityAuditEvent } from '../types';
import {
  MissingTableCellValue,
  TableIconButton,
} from '@/routes/Audit/components/Events/AuditTable';
import { ExpandCircleDownOutlined as UserMoreIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { AccountCircleOutlined as UserIcon } from '@mui/icons-material';
import api from '@/lib/api';
import UserAvatar from '@/routes/Audit/components/UserInfoTableCell/UserAvatar';
import LoadingSpinner from '@/components/LoadingSpinner';
import { PersonOff as UserDeletedIcon } from '@mui/icons-material';
import {
  UserTableCellContent,
  UserDialogTitle,
  UserRoleIndicator,
  UserDialogContent,
  UserDeletedMessage,
} from '@/routes/Audit/components/UserInfoTableCell/styled-components';
import { SuperUser } from '@/routes/super-users/types';

type SuperUserInfoTableCellProps = {
  auditEvent: AdminAuditEvent | AdminSecurityAuditEvent;
};

type SuperUserResponse = {
  superUser: SuperUser | null;
};

const SuperUserInfoTableCell = ({
  auditEvent,
}: SuperUserInfoTableCellProps) => {
  const [loading, setLoading] = useState(false);
  const [superUserExists, setUserExists] = useState<boolean | null>(null);
  const [superUser, setUser] = useState<SuperUser | null>(null);

  const [superUserInfoDialogOpen, setUserInfoDialogOpen] = useState(false);

  const openUserInfoDialog = async (): Promise<void> => {
    setUserInfoDialogOpen(true);

    if (superUserExists !== null) {
      return;
    }

    setLoading(true);

    const { superUser } = await api.get<SuperUserResponse>(
      `users/${auditEvent.superUserId}`,
    );

    setUserExists(superUser !== null);
    setUser(superUser);

    setLoading(false);
  };

  const superUserLabel =
    auditEvent.superUserName ??
    auditEvent.superUserEmail ??
    auditEvent.superUserId ??
    null;

  return (
    <>
      <TableCell>
        <UserTableCellContent>
          {superUserLabel === null ? (
            <MissingTableCellValue>(no user)</MissingTableCellValue>
          ) : (
            <>
              {superUserLabel}
              <Tooltip title="User info">
                <TableIconButton onClick={openUserInfoDialog}>
                  <UserMoreIcon />
                </TableIconButton>
              </Tooltip>
            </>
          )}
        </UserTableCellContent>
      </TableCell>
      {superUserLabel !== null && (
        <Dialog
          open={superUserInfoDialogOpen}
          onClose={() => setUserInfoDialogOpen(false)}
        >
          <UserDialogTitle>
            {loading && <LoadingSpinner size={24} color="secondary" />}
            {!loading && superUser !== null && (
              <UserAvatar userName={superUser.profile.name} />
            )}
            {!loading && superUser === null && <UserIcon />}
            {superUserLabel}
            {!loading && superUser !== null && (
              <UserRoleIndicator>
                {superUser.role.toLowerCase()}
              </UserRoleIndicator>
            )}
          </UserDialogTitle>
          <UserDialogContent>
            {(superUser?.profile.name || auditEvent.superUserName) && (
              <p>
                <strong>Name:</strong>{' '}
                {superUser?.profile.name ?? auditEvent.superUserName}
              </p>
            )}
            {(superUser?.email || auditEvent.superUserEmail) && (
              <p>
                <strong>E-mail address:</strong>{' '}
                {superUser?.email ?? auditEvent.superUserEmail}
              </p>
            )}
            {auditEvent.superUserId && (
              <p>
                <strong>ID:</strong> {auditEvent.superUserId}
              </p>
            )}
            {!loading && !superUserExists && (
              <UserDeletedMessage>
                <UserDeletedIcon />
                This user has been deleted
              </UserDeletedMessage>
            )}
          </UserDialogContent>
          <Dialog.Actions>
            <Dialog.Actions.DoneButton
              onClick={() => setUserInfoDialogOpen(false)}
            />
          </Dialog.Actions>
        </Dialog>
      )}
    </>
  );
};

export default SuperUserInfoTableCell;
