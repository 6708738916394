import useSwr from '@/lib/useSwr';
import { Perspective } from '@/routes/perspectives/types';
import SavingIndicator from '../components/SavingIndicator';
import IconPreview from '../components/IconPreview';
import useImageAsDataUrl from '../useImageAsDataUrl';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import SecondaryIconActionButton from '../components/SecondaryIconActionButton';
import UploadIconButton from '../components/UploadIconButton';
import { useState } from 'react';
import IconActionButton from '../components/IconActionButton';
import useRefreshPerspectives from '../../../useRefreshPerspectives';
import api, { HttpMethod } from '@/lib/api';
import DeletePerspectiveIcon from './DeletePerspectiveIcon';
import IconRetrievalErrorIndicator from './IconRetrievalErrorIndicator';

type PerspectiveIconManagerProps = {
  perspective: Perspective;
};

const PerspectiveIconManager = ({
  perspective,
}: PerspectiveIconManagerProps) => {
  const refreshPerspectives = useRefreshPerspectives();

  const {
    isLoading,
    data: originalIcon,
    error,
  } = useSwr<Blob>(`/perspectives/icons/${perspective.icon}`);
  const originalIconAsDataUrl = useImageAsDataUrl(originalIcon);

  const [newIconFile, setNewIconFile] = useState<File | null>(null);
  const newIconAsDataUrl = useImageAsDataUrl(newIconFile);

  const [saving, setSaving] = useState(false);

  const deleteIcon = async () => {
    setSaving(true);

    await api.delete(`/perspectives/${perspective.id}/icon`);
    await refreshPerspectives();

    setSaving(false);
  };

  const saveNewIcon = async () => {
    setSaving(true);

    const formData = new FormData();

    formData.append('icon', newIconFile!);

    await api.call(`/perspectives/${perspective.id}/icon`, {
      method: HttpMethod.POST,
      body: formData,
    });

    await refreshPerspectives();

    setNewIconFile(null);
    setSaving(false);
  };

  if (isLoading || originalIconAsDataUrl === null || saving) {
    return <SavingIndicator />;
  }

  if (error || !originalIcon) {
    return <IconRetrievalErrorIndicator />;
  }

  return (
    <>
      <IconPreview src={newIconAsDataUrl ?? originalIconAsDataUrl} />
      {newIconFile === null ? (
        <>
          <UploadIconButton
            onUpload={file => setNewIconFile(file)}
            iconPreviewVisible
          >
            <EditIcon />
          </UploadIconButton>
          <DeletePerspectiveIcon
            perspective={perspective}
            onDelete={deleteIcon}
          />
        </>
      ) : (
        <>
          <IconActionButton onClick={saveNewIcon}>
            <SaveIcon />
          </IconActionButton>
          <SecondaryIconActionButton onClick={() => setNewIconFile(null)}>
            <ClearIcon />
          </SecondaryIconActionButton>
        </>
      )}
    </>
  );
};

export default PerspectiveIconManager;
