import React, { useState } from 'react';

// We're working with the maximum 32-bit signed integer because that's the
// largest numeric value the database accepts.
const MAX_SIGNED_INT32 = 2147483647;

const isBackendSafeNumber = (number: number): boolean => {
  if (!Number.isFinite(number)) {
    return false;
  }

  return Math.abs(number) <= MAX_SIGNED_INT32;
};

const useIdState = (): [
  number | null,
  (event: React.ChangeEvent<HTMLInputElement>) => void,
] => {
  const [id, setId] = useState<number | null>(null);

  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === '') {
      setId(null);

      return;
    }

    let id = Number(event.target.value);

    if (Number.isNaN(id)) {
      setId(null);

      return;
    }

    if (!Number.isInteger(id)) {
      id = Math.floor(id);
    }

    if (!isBackendSafeNumber(id)) {
      setId(MAX_SIGNED_INT32);

      return;
    }

    if (id < 1) {
      setId(1);

      return;
    }

    setId(id);
  };

  return [id, handleIdChange];
};

export default useIdState;
