import WideAppContent from '@/components/WideAppContent';
import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const PerspectiveManagerWrapper = styled(WideAppContent)({
  '&&': {
    position: 'relative',
    // Increasing the top padding is necessary to accomodate the perspective
    // column chunk controls
    paddingTop: 76,
    flex: 1,
    display: 'flex',
    overflowX: 'auto',
    [mediaQuery(1310)]: {
      marginLeft: -48,
      marginRight: -48,
      paddingLeft: 48,
      paddingRight: 48,
      paddingBottom: 12,
    },
  },
});

export default PerspectiveManagerWrapper;
