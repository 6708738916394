import styled from '@emotion/styled';
import {
  TableContainer,
  Table,
  Paper,
  TableCell,
  IconButton,
  TableProps,
} from '@mui/material';

const StyledTable = styled(Table)(({ theme }) => ({
  '.MuiTableCell-root': {
    fontSize: 13,
    borderColor: theme.palette.divider,
    code: {
      fontSize: 12,
    },
  },
}));

const AuditTable = (props: TableProps) => (
  <TableContainer component={Paper}>
    <StyledTable {...props} />
  </TableContainer>
);

export default AuditTable;

type HeadTableCellProps = {
  width?: number;
};

export const HeadTableCell = styled(TableCell)<HeadTableCellProps>(
  ({ width }) => ({
    padding: '6px 16px',
    width: width ?? undefined,
  }),
);

export const SecondaryTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const MissingTableCellValue = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontStyle: 'italic',
}));

export const TableIconButton = styled(IconButton)({
  marginTop: -5,
  marginBottom: -5,
  padding: 5,
  '.MuiSvgIcon-root': {
    fontSize: '1.2rem',
  },
});

type JsonTableCellContentProps = {
  children: string;
};

export const JsonTableCellContent = ({
  children,
}: JsonTableCellContentProps) => (
  <code>{children.replaceAll('":"', '": "').replaceAll('","', '", "')}</code>
);
