import {
  AdminSecurityAuditEvent,
  AdminSecurityAuditEventsSearchInput,
} from '../../types';
import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';
import EventsWrapper from '@/routes/Audit/components/Events/EventsWrapper';
import NoMatchesMessage from '@/routes/Audit/components/Events/NoMatchesMessage';
import LoadMoreButton from '@/routes/Audit/components/Events/LoadMoreButton';
import AdminSecurityAuditEventCount from './AdminSecurityAuditEventCount';
import AdminSecurityAuditEventTable from './AdminSecurityAuditEventTable';
import useAuditEventsState from '@/routes/Audit/useAuditEventsState';

type AdminSecurityAuditEventsProps = {
  searchInput: AdminSecurityAuditEventsSearchInput;
};

const AdminSecurityAuditEvents = ({
  searchInput,
}: AdminSecurityAuditEventsProps) => {
  const [
    loading,
    securityAuditEvents,
    canLoadMore,
    loadMoreSecurityAuditEvents,
  ] = useAuditEventsState<AdminSecurityAuditEvent>(
    'admin-security-audit-events',
    searchInput,
  );

  return (
    <EventsWrapper>
      {loading && securityAuditEvents.length === 0 ? (
        <LoadingSpinnerWithText noPadding>
          Loading security events
        </LoadingSpinnerWithText>
      ) : (
        <>
          {securityAuditEvents.length === 0 ? (
            <NoMatchesMessage>
              No security events matched your search
            </NoMatchesMessage>
          ) : (
            <>
              <AdminSecurityAuditEventCount
                searchInput={searchInput}
                shownSecurityAuditEventCount={securityAuditEvents.length}
              />
              <AdminSecurityAuditEventTable
                securityAuditEvents={securityAuditEvents}
              />
              {canLoadMore && (
                <LoadMoreButton
                  loading={loading}
                  onClick={loadMoreSecurityAuditEvents}
                />
              )}
            </>
          )}
        </>
      )}
    </EventsWrapper>
  );
};

export default AdminSecurityAuditEvents;
