import { useState } from 'react';
import { TableCell, Tooltip } from '@mui/material';
import { AddCircleOutline as MoreIcon } from '@mui/icons-material';
import { TableIconButton } from '@/routes/Audit/components/Events/AuditTable';
import Dialog from '@/components/Dialog';
import styled from '@emotion/styled';
import { AdminAuditEvent } from '@/routes/AdminAudit/types';

type AdminDetailsTableCellProps = {
  auditEvent: AdminAuditEvent;
  onOpen: () => void;
  onClose: () => void;
};

const StyledDialogContent = styled(Dialog.Content)(({ theme }) => ({
  color: theme.palette.text.secondary,
  p: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

const AdminDetailsTableCell = ({
  auditEvent,
  onOpen,
  onClose,
}: AdminDetailsTableCellProps) => {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const openDetailsDialog = (): void => {
    setDetailsDialogOpen(true);

    onOpen();
  };

  const closeDetailsDialog = (): void => {
    setDetailsDialogOpen(false);

    onClose();
  };

  return (
    <>
      <TableCell>
        <Tooltip title="View details">
          <TableIconButton onClick={openDetailsDialog}>
            <MoreIcon />
          </TableIconButton>
        </Tooltip>
      </TableCell>
      <Dialog open={detailsDialogOpen} onClose={closeDetailsDialog}>
        <Dialog.Title>Event details</Dialog.Title>
        <StyledDialogContent>
          {auditEvent.attribute && (
            <p>
              <strong>Attribute:</strong> <code>{auditEvent.attribute}</code>
            </p>
          )}
          {auditEvent.oldValue && (
            <p>
              <strong>Old value:</strong> <code>{auditEvent.oldValue}</code>
            </p>
          )}
          {auditEvent.newValue && (
            <p>
              <strong>New value:</strong> <code>{auditEvent.newValue}</code>
            </p>
          )}
          {auditEvent.additionalInfo && (
            <p>
              <strong>Additional info:</strong>{' '}
              <code>{JSON.stringify(auditEvent.additionalInfo, null, 4)}</code>
            </p>
          )}
          <p>
            <strong>Origin IP address:</strong>{' '}
            <code>{auditEvent.originIp}</code>
          </p>
        </StyledDialogContent>
        <Dialog.Actions>
          <Dialog.Actions.DoneButton onClick={closeDetailsDialog} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default AdminDetailsTableCell;
