import { useContext } from 'react';
import { AuditEvent, SecurityAuditEvent } from '../types';
import SchoolsContext from '../SchoolsContext';
import { TableCell } from '@mui/material';
import { MissingTableCellValue } from './Events/AuditTable';

type SchoolTableCellProps = {
  auditEvent: AuditEvent | SecurityAuditEvent;
};

const SchoolTableCell = ({ auditEvent }: SchoolTableCellProps) => {
  const schools = useContext(SchoolsContext);

  if (schools === null) {
    return <TableCell />;
  }

  const school =
    auditEvent.schoolId === null ? null : schools[auditEvent.schoolId];

  return (
    <TableCell>
      {typeof school === 'undefined' || school === null ? (
        <MissingTableCellValue>
          (school unspecified or deleted)
        </MissingTableCellValue>
      ) : (
        school.displayName
      )}
    </TableCell>
  );
};

export default SchoolTableCell;
