import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  PaperProps,
} from '@mui/material';
import styled from '@emotion/styled';
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import React from 'react';
import mediaQuery from '@/lib/styling/mediaQuery';

const FormPaper = (props: Omit<PaperProps<'form'>, 'component'>) => (
  <Paper component="form" {...props} />
);

const SearchForm = styled(FormPaper)({
  marginRight: 24,
  padding: 12,
  width: 300,
  minWidth: 300,
  display: 'flex',
  flexDirection: 'column',
  [mediaQuery(1600)]: {
    marginRight: 0,
    marginBottom: 28,
    width: 600,
    maxWidth: '100%',
    boxSizing: 'border-box',
    order: 1,
  },
});

export default SearchForm;

export const SearchFormGroup = styled(Accordion)({
  margin: '0 !important',
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '.MuiAccordionSummary-root': {
    padding: '6px 0',
    minHeight: '0 !important',
  },
  '.MuiAccordionSummary-content': {
    margin: '0 !important',
  },
  '.MuiAccordionDetails-root': {
    padding: 0,
  },
  '.MuiFormControl-root': {
    marginTop: 12,
    ':first-of-type': {
      marginTop: 0,
    },
    ':last-of-type': {
      marginBottom: 12,
    },
  },
});

type SearchFormGroupTitleProps = {
  groupId: string;
  children: React.ReactNode;
};

export const SearchFormGroupTitle = ({
  groupId,
  children,
}: SearchFormGroupTitleProps) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls={`${groupId}-details`}
    id={`${groupId}-header`}
  >
    <h4>{children}</h4>
  </AccordionSummary>
);

export const SearchFormGroupContent = styled(AccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
});

export const SearchFormFieldWrapper = styled('div')({
  display: 'flex',
  '.MuiFormControl-root': {
    marginTop: 0,
    flex: 1,
    boxSizing: 'border-box',
  },
});

const SearchFormFooter = styled('footer')({
  marginTop: 12,
  display: 'flex',
  justifyContent: 'flex-end',
});

type SearchButtonProps = {
  disabled: boolean;
};

export const SearchButton = ({ disabled }: SearchButtonProps) => (
  <SearchFormFooter>
    <Button
      variant="contained"
      color="primary"
      startIcon={<SearchIcon />}
      size="small"
      type="submit"
      disabled={disabled}
      disableElevation
    >
      Search
    </Button>
  </SearchFormFooter>
);
