import useSwr from '@/lib/useSwr';
import { PerspectiveCohort } from '../../types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Alert } from '@mui/material';
import NotFoundMessage from '@/components/NotFoundMessage';
import PerspectiveCohortList from './PerspectiveCohortList';
import PerspectiveCohortListItem from './PerspectiveCohortListItem';
import PerspectiveCohortPublishingStatus from './PerspectiveCohortPublishingStatus';
import PerspectiveCohortManageButton from './PerspectiveCohortManageButton';

const PerspectiveCohortManager = () => {
  const {
    isLoading,
    data: perspectiveCohorts,
    error,
  } = useSwr<PerspectiveCohort[]>('/perspectives/cohorts');

  if (isLoading) {
    return <LoadingSpinner margin={48} centered />;
  }

  if (error || typeof perspectiveCohorts === 'undefined') {
    return (
      <Alert severity="error">
        Something went wrong while trying to load perspective cohorts.
      </Alert>
    );
  }

  if (perspectiveCohorts.length === 0) {
    return <NotFoundMessage>No perspective cohorts found</NotFoundMessage>;
  }

  return (
    <PerspectiveCohortList>
      {perspectiveCohorts.map(perspectiveCohort => (
        <PerspectiveCohortListItem key={perspectiveCohort.id}>
          <h3>{perspectiveCohort.name}</h3>
          <PerspectiveCohortPublishingStatus
            perspectiveCohort={perspectiveCohort}
          />
          <PerspectiveCohortManageButton
            perspectiveCohort={perspectiveCohort}
          />
        </PerspectiveCohortListItem>
      ))}
    </PerspectiveCohortList>
  );
};

export default PerspectiveCohortManager;
