import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const AuditPageHeader = styled('header')({
  marginTop: 32,
  h1: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
  },
  [mediaQuery(1600)]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default AuditPageHeader;
