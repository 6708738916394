import StyledDrawer from './StyledDrawer';
import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Home as HomeIcon,
  School as SchoolsIcon,
  DriveFileMove as DataMigrationsIcon,
  AccountTree as PerspectivesIcon,
  PeopleAlt as UsersIcon,
  ManageAccounts as ProfileSettingsIcon,
  Security as SecurityIcon,
  AdminPanelSettingsOutlined as AdminSecurityIcon,
} from '@mui/icons-material';
import EgodactOnly from '@/components/EgodactOnly';
import DrawerLink from './DrawerLink';

const drawerLinks = [
  {
    to: '/',
    label: 'Home',
    icon: <HomeIcon />,
  },
  {
    to: '/schools',
    label: 'Schools',
    icon: <SchoolsIcon />,
    egodactOnly: true,
  },
  {
    to: '/perspectives',
    label: 'Perspectives',
    icon: <PerspectivesIcon />,
  },
  {
    to: '/users',
    label: 'Users',
    icon: <UsersIcon />,
    egodactOnly: true,
  },
  {
    to: '/data-migrations',
    label: 'Data migrations',
    icon: <DataMigrationsIcon />,
    egodactOnly: true,
  },
  {
    to: '/audit',
    label: 'Audit & security',
    icon: <SecurityIcon />,
    egodactOnly: true,
  },
  {
    to: '/admin-audit',
    label: 'Admin audit & security',
    icon: <AdminSecurityIcon />,
    egodactOnly: true,
  },
];

const AppDrawer = () => (
  <StyledDrawer variant="permanent" anchor="left">
    <Toolbar />
    <Divider />
    <List>
      {drawerLinks.map((drawerLink, index) => (
        <EgodactOnly active={drawerLink.egodactOnly} key={index}>
          <ListItem disablePadding>
            <DrawerLink to={drawerLink.to}>
              <ListItemIcon>{drawerLink.icon}</ListItemIcon>
              <ListItemText>{drawerLink.label}</ListItemText>
            </DrawerLink>
          </ListItem>
        </EgodactOnly>
      ))}
    </List>
    <Divider />
    <List>
      <ListItem disablePadding>
        <DrawerLink to="/profile">
          <ListItemIcon>
            <ProfileSettingsIcon />
          </ListItemIcon>
          <ListItemText>My profile</ListItemText>
        </DrawerLink>
      </ListItem>
    </List>
  </StyledDrawer>
);

export default AppDrawer;
