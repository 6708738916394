import styled from '@emotion/styled';
import { Avatar, AvatarProps } from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';

type StyledAvatarProps = AvatarProps & {
  backgroundColor: string;
};

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: isPropValid,
})<StyledAvatarProps>(({ backgroundColor }) => ({
  margin: 0,
  marginRight: 20,
  backgroundColor: backgroundColor,
  fontWeight: 300,
  '&&&': {
    width: 32,
    height: 32,
    fontSize: 16,
  },
}));

export default StyledAvatar;
