import useApiResponse from '@/lib/useApiResponse';
import GraphLoadingPlaceholder from '@/routes/Audit/components/AuditEventVolumesGraph/GraphLoadingPlaceholder';
import InnerAuditEventVolumesGraph from '@/routes/Audit/components/AuditEventVolumesGraph/InnerAuditEventVolumesGraph';

type AdminAuditStatsResponse = {
  stats: {
    adminAuditEvents: Record<string, number>;
    adminSecurityAuditEvents: Record<string, number>;
  };
};

const AdminAuditEventVolumesGraph = () => {
  const [loading, auditStats] =
    useApiResponse<AdminAuditStatsResponse>('admin-audit-stats');

  if (loading) {
    return <GraphLoadingPlaceholder />;
  }

  return (
    <InnerAuditEventVolumesGraph
      auditEventStats={{
        label: 'Admin audit events',
        data: auditStats.stats.adminAuditEvents,
      }}
      securityAuditEventStats={{
        label: 'Admin security events',
        data: auditStats.stats.adminSecurityAuditEvents,
      }}
    />
  );
};

export default AdminAuditEventVolumesGraph;
