import React from 'react';
import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { LoadingButton } from '@mui/lab';
import { AddCircleOutlineOutlined as MoreIcon } from '@mui/icons-material';

type LoadMoreButtonProps = {
  loading: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const LoadingButtonWrapper = styled('footer')({
  marginTop: 24,
  [mediaQuery(1600)]: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const LoadMoreButton = ({ loading, onClick }: LoadMoreButtonProps) => (
  <LoadingButtonWrapper>
    <LoadingButton
      startIcon={<MoreIcon />}
      variant="outlined"
      color="secondary"
      loading={loading}
      onClick={onClick}
      loadingPosition="start"
      disableElevation
    >
      Load more
    </LoadingButton>
  </LoadingButtonWrapper>
);

export default LoadMoreButton;
