import AuditTable, {
  HeadTableCell,
  JsonTableCellContent,
  MissingTableCellValue,
  SecondaryTableCell,
} from '@/routes/Audit/components/Events/AuditTable';
import { AdminSecurityAuditEvent } from '../../types';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import SuperUserInfoTableCell from '../../components/SuperUserInfoTableCell';

type AdminSecurityAuditEventTableProps = {
  securityAuditEvents: AdminSecurityAuditEvent[];
};

const AdminSecurityAuditEventTable = ({
  securityAuditEvents,
}: AdminSecurityAuditEventTableProps) => (
  <AuditTable>
    <TableHead>
      <TableRow>
        <HeadTableCell width={50}>ID</HeadTableCell>
        <HeadTableCell width={100}>Event type</HeadTableCell>
        <HeadTableCell>Additional info</HeadTableCell>
        <HeadTableCell width={150}>User</HeadTableCell>
        <HeadTableCell width={150}>Origin IP</HeadTableCell>
        <HeadTableCell width={120}>Created at</HeadTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {securityAuditEvents.map(securityAuditEvent => (
        <TableRow key={securityAuditEvent.id}>
          <TableCell component="th">#{securityAuditEvent.id}</TableCell>
          <TableCell>{securityAuditEvent.eventType}</TableCell>
          <TableCell>
            {securityAuditEvent.additionalInfo ? (
              <JsonTableCellContent>
                {securityAuditEvent.additionalInfo}
              </JsonTableCellContent>
            ) : (
              <MissingTableCellValue>(unspecified)</MissingTableCellValue>
            )}
          </TableCell>
          <SuperUserInfoTableCell auditEvent={securityAuditEvent} />
          <TableCell>
            <code>{securityAuditEvent.originIp}</code>
          </TableCell>
          <SecondaryTableCell>
            {moment(securityAuditEvent.createdAt).format('L LT')}
          </SecondaryTableCell>
        </TableRow>
      ))}
    </TableBody>
  </AuditTable>
);

export default AdminSecurityAuditEventTable;
