import useSWR, {
  KeyedMutator,
  MutatorOptions,
  SWRConfiguration,
  SWRResponse,
} from 'swr';
import api from './api';

const useSwr = <Data, Error = unknown>(
  key: string,
  options?: SWRConfiguration,
): SWRResponse<Data, Error> => {
  const { mutate, ...swrResponse } = useSWR<Data, Error>(
    key,
    api.call,
    options,
  );

  const customMutate: KeyedMutator<Data> = (data, options) => {
    if (typeof options === 'boolean') {
      return mutate<Data>(data, options);
    }

    return mutate<Data>(data, {
      revalidate: false,
      ...(options as MutatorOptions<Data, Data> | undefined),
    });
  };

  return { mutate: customMutate, ...swrResponse };
};

export default useSwr;
