import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const CountTextPlaceholder = styled('div')({
  marginBottom: 36,
  [mediaQuery(1600)]: {
    marginBottom: 42,
  },
});

export default CountTextPlaceholder;
