import styled from '@emotion/styled';
import { PERSPECTIVE_CHUNK_SIZE } from './constants';
import { CARD_WIDTH } from '../PerspectiveCard/styleConstants';
import React from 'react';
import { ButtonBase, alpha } from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { common } from '@mui/material/colors';
import mediaQuery from '@/lib/styling/mediaQuery';

type ChunkControlsProps = {
  chunkCount: number;
  visibleChunk: number;
  perspectiveCount: number;
  visibleChunkPerspectiveCount: number;
  setVisibleChunk: React.Dispatch<React.SetStateAction<number>>;
};

const ChunkControlsWrapper = styled('header')(({ theme }) => ({
  position: 'absolute',
  top: 24,
  left: 36,
  width: CARD_WIDTH,
  height: 36,
  backgroundColor: theme.palette.action.selected,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 4,
  h4: {
    fontWeight: 400,
    fontSize: 15,
  },
  [mediaQuery(1310)]: {
    left: 48,
  },
}));

const ControlButton = styled(ButtonBase)(({ theme }) => ({
  paddingLeft: 4,
  paddingRight: 4,
  height: '100%',
  transition: theme.transitions.create('all'),
  ':hover': {
    backgroundColor: theme.palette.action.hover,
  },
  ':first-of-type': {
    borderRight: `2px solid ${theme.palette.divider}`,
  },
  ':last-child': {
    borderLeft: `2px solid ${theme.palette.divider}`,
  },
  ':disabled': {
    color: alpha(common.black, 0.26),
  },
}));

const ChunkControls = ({
  chunkCount,
  visibleChunk,
  perspectiveCount,
  visibleChunkPerspectiveCount,
  setVisibleChunk,
}: ChunkControlsProps) => {
  const moveToPreviousChunk = () => {
    setVisibleChunk(visibleChunk => visibleChunk - 1);
  };

  const moveToNextChunk = () => {
    setVisibleChunk(visibleChunk => visibleChunk + 1);
  };

  const visiblePerspectiveRangeStart =
    visibleChunk * PERSPECTIVE_CHUNK_SIZE + 1;
  const visiblePerspectiveRangeEnd =
    visiblePerspectiveRangeStart - 1 + visibleChunkPerspectiveCount;

  return (
    <ChunkControlsWrapper>
      <ControlButton onClick={moveToPreviousChunk} disabled={visibleChunk <= 0}>
        <ChevronLeftIcon />
      </ControlButton>
      <h4>
        {visiblePerspectiveRangeStart === visiblePerspectiveRangeEnd ? (
          <>
            Perspective {visiblePerspectiveRangeStart}/{perspectiveCount}
          </>
        ) : (
          <>
            Perspectives {visiblePerspectiveRangeStart}-
            {visiblePerspectiveRangeEnd}/{perspectiveCount}
          </>
        )}
      </h4>
      <ControlButton
        onClick={moveToNextChunk}
        disabled={visibleChunk >= chunkCount - 1}
      >
        <ChevronRightIcon />
      </ControlButton>
    </ChunkControlsWrapper>
  );
};

export default ChunkControls;
