import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './routes/Home';
import SchoolManager from './routes/schools/SchoolManager';
import CreateSchool from './routes/schools/CreateSchool';
import UpdateSchool from './routes/schools/UpdateSchool';
import NotFound from './routes/NotFound';
import SuperUserManager from './routes/super-users/SuperUserManager';
import CreateSuperUser from './routes/super-users/CreateSuperUser';
import Login from './routes/auth/Login';
import AccountSetup from './routes/auth/AccountSetup';
import RequestPasswordReset from './routes/auth/RequestPasswordReset';
import PasswordReset from './routes/auth/PasswordReset';
import PerspectiveCohorts from './routes/perspectives/PerspectiveCohorts';
import ProfileSettings from './routes/ProfileSettings';
import EgodactOnlyRoute from './components/EgodactOnlyRoute';
import RootLayout from './layouts/RootLayout';
import AuthRootLayout from './layouts/AuthRootLayout';
import ErrorScreen from './routes/ErrorScreen';
import AuthErrorScreen from './routes/AuthErrorScreen';
import CreatePerspectiveCohort from './routes/perspectives/CreatePerspectiveCohort';
import PerspectiveCohortWithPerspectiveManager from './routes/perspectives/PerspectiveCohortWithPerspectiveManager';
import useAutoSignOut from './useAutoSignOut';
import CreateDataMigration from './routes/data-migrations/CreateDataMigration';
import DataMigrationsManager from './routes/data-migrations/DataMigrationsManager';
import Audit from './routes/Audit';
import AdminAudit from './routes/AdminAudit';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorScreen />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/schools',
        element: (
          <EgodactOnlyRoute>
            <SchoolManager />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/schools/create',
        element: (
          <EgodactOnlyRoute>
            <CreateSchool />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/schools/:id',
        element: (
          <EgodactOnlyRoute>
            <UpdateSchool />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/perspectives',
        element: <PerspectiveCohorts />,
      },
      {
        path: '/perspectives/create',
        element: <CreatePerspectiveCohort />,
      },
      {
        path: '/perspectives/:id',
        element: <PerspectiveCohortWithPerspectiveManager />,
      },
      {
        path: '/users',
        element: (
          <EgodactOnlyRoute>
            <SuperUserManager />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/users/create',
        element: (
          <EgodactOnlyRoute>
            <CreateSuperUser />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/data-migrations',
        element: (
          <EgodactOnlyRoute>
            <DataMigrationsManager />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/data-migrations/create',
        element: (
          <EgodactOnlyRoute>
            <CreateDataMigration />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/audit',
        element: (
          <EgodactOnlyRoute>
            <Audit />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/admin-audit',
        element: (
          <EgodactOnlyRoute>
            <AdminAudit />
          </EgodactOnlyRoute>
        ),
      },
      {
        path: '/profile',
        element: <ProfileSettings />,
      },
    ],
  },
  {
    path: '/auth',
    element: <AuthRootLayout />,
    errorElement: <AuthErrorScreen />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'invite/:token',
        element: <AccountSetup />,
      },
      {
        path: 'forgot-password',
        element: <RequestPasswordReset />,
      },
      {
        path: 'reset-password/:token',
        element: <PasswordReset />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

const AppContentRenderer = () => {
  useAutoSignOut();

  return <RouterProvider router={router} />;
};

export default AppContentRenderer;
