import useRandomColor from '@/lib/styling/useRandomColor';
import StyledAvatar from './StyledAvatar';

type UserAvatarProps = {
  userName: string;
};

const UserAvatar = ({ userName }: UserAvatarProps) => {
  const avatarColor = useRandomColor();

  return (
    <StyledAvatar backgroundColor={avatarColor[600]}>
      {userName.charAt(0)}
    </StyledAvatar>
  );
};

export default UserAvatar;
