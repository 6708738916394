import { useState } from 'react';
import { AdminAuditEventsSearchInput } from '../types';
import PageContentGrid from '@/routes/Audit/components/PageContentGrid';
import SearchFormWrapper from '@/routes/Audit/components/SearchForm/SearchFormWrapper';
import AdminAuditEventsSearchForm from './AdminAuditEventsSearchForm';
import AdminAuditEventVolumesGraph from '../components/AdminAuditEventVolumesGraph';
import AdminAuditEvents from './AdminAuditEvents';

const AdminAuditEventsWithSearchForm = () => {
  const [auditEventsSearchInput, setAuditEventsSearchInput] =
    useState<AdminAuditEventsSearchInput>({});

  return (
    <PageContentGrid>
      <SearchFormWrapper>
        <AdminAuditEventsSearchForm
          lastSearchInput={auditEventsSearchInput}
          onSearch={auditEventsSearchInput => {
            setAuditEventsSearchInput(auditEventsSearchInput);
          }}
        />
        <AdminAuditEventVolumesGraph />
      </SearchFormWrapper>
      <AdminAuditEvents searchInput={auditEventsSearchInput} />
    </PageContentGrid>
  );
};

export default AdminAuditEventsWithSearchForm;
