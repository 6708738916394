import { AdminAuditEventsSearchInput, AdminAuditEvent } from '../../types';
import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';
import AdminAuditEventCount from './AdminAuditEventCount';
import AdminAuditEventTable from './AdminAuditEventTable';
import EventsWrapper from '@/routes/Audit/components/Events/EventsWrapper';
import NoMatchesMessage from '@/routes/Audit/components/Events/NoMatchesMessage';
import LoadMoreButton from '@/routes/Audit/components/Events/LoadMoreButton';
import useAuditEventsState from '@/routes/Audit/useAuditEventsState';

type AdminAuditEventsProps = {
  searchInput: AdminAuditEventsSearchInput;
};

const AdminAuditEvents = ({ searchInput }: AdminAuditEventsProps) => {
  const [loading, auditEvents, canLoadMore, loadMoreAuditEvents] =
    useAuditEventsState<AdminAuditEvent>('admin-audit-events', searchInput);

  return (
    <EventsWrapper>
      {loading && auditEvents.length === 0 ? (
        <LoadingSpinnerWithText noPadding>
          Loading audit events
        </LoadingSpinnerWithText>
      ) : (
        <>
          {auditEvents.length === 0 ? (
            <NoMatchesMessage>
              No audit events matched your search
            </NoMatchesMessage>
          ) : (
            <>
              <AdminAuditEventCount
                searchInput={searchInput}
                shownAuditEventCount={auditEvents.length}
              />
              <AdminAuditEventTable auditEvents={auditEvents} />
              {canLoadMore && (
                <LoadMoreButton
                  loading={loading}
                  onClick={loadMoreAuditEvents}
                />
              )}
            </>
          )}
        </>
      )}
    </EventsWrapper>
  );
};

export default AdminAuditEvents;
