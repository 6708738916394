import { useState } from 'react';
import { TableCell, Tooltip } from '@mui/material';
import { AuditEvent, SecurityAuditEvent, User } from '../../types';
import { MissingTableCellValue, TableIconButton } from '../Events/AuditTable';
import { ExpandCircleDownOutlined as UserMoreIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { AccountCircleOutlined as UserIcon } from '@mui/icons-material';
import api from '@/lib/api';
import UserAvatar from './UserAvatar';
import LoadingSpinner from '@/components/LoadingSpinner';
import { PersonOff as UserDeletedIcon } from '@mui/icons-material';
import { UserRole } from '../../enums';
import {
  UserTableCellContent,
  UserDialogTitle,
  UserRoleIndicator,
  UserDialogContent,
  UserDeletedMessage,
} from './styled-components';

type UserInfoTableCellProps = {
  auditEvent: AuditEvent | SecurityAuditEvent;
};

type UserResponse = {
  user: User | null;
};

const UserInfoTableCell = ({ auditEvent }: UserInfoTableCellProps) => {
  const [loading, setLoading] = useState(false);
  const [userExists, setUserExists] = useState<boolean | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const [userInfoDialogOpen, setUserInfoDialogOpen] = useState(false);

  const openUserInfoDialog = async (): Promise<void> => {
    setUserInfoDialogOpen(true);

    if (userExists !== null) {
      return;
    }

    setLoading(true);

    const { user } = await api.get<UserResponse>(
      `user-info/${auditEvent.userId}`,
    );

    setUserExists(user !== null);
    setUser(user);

    setLoading(false);
  };

  const userLabel =
    auditEvent.userName ?? auditEvent.userEmail ?? auditEvent.userId ?? null;

  return (
    <>
      <TableCell>
        <UserTableCellContent>
          {userLabel === null ? (
            <MissingTableCellValue>(no user)</MissingTableCellValue>
          ) : (
            <>
              {userLabel}
              <Tooltip title="User info">
                <TableIconButton onClick={openUserInfoDialog}>
                  <UserMoreIcon />
                </TableIconButton>
              </Tooltip>
            </>
          )}
        </UserTableCellContent>
      </TableCell>
      {userLabel !== null && (
        <Dialog
          open={userInfoDialogOpen}
          onClose={() => setUserInfoDialogOpen(false)}
        >
          <UserDialogTitle>
            {loading && <LoadingSpinner size={24} color="secondary" />}
            {!loading && user !== null && <UserAvatar userName={user.name} />}
            {!loading && user === null && <UserIcon />}
            {userLabel}
            {!loading && user !== null && user.role !== UserRole.STUDENT && (
              <UserRoleIndicator>{user.role.toLowerCase()}</UserRoleIndicator>
            )}
          </UserDialogTitle>
          <UserDialogContent>
            {(user?.name || auditEvent.userName) && (
              <p>
                <strong>Name:</strong> {user?.name ?? auditEvent.userName}
              </p>
            )}
            {(user?.email || auditEvent.userEmail) && (
              <p>
                <strong>E-mail address:</strong>{' '}
                {user?.email ?? auditEvent.userEmail}
              </p>
            )}
            {auditEvent.userId && (
              <p>
                <strong>ID:</strong> {auditEvent.userId}
              </p>
            )}
            {!loading && !userExists && (
              <UserDeletedMessage>
                <UserDeletedIcon />
                This user has been deleted
              </UserDeletedMessage>
            )}
          </UserDialogContent>
          <Dialog.Actions>
            <Dialog.Actions.DoneButton
              onClick={() => setUserInfoDialogOpen(false)}
            />
          </Dialog.Actions>
        </Dialog>
      )}
    </>
  );
};

export default UserInfoTableCell;
