import { useState } from 'react';
import { AdminSecurityAuditEventsSearchInput } from '../types';
import PageContentGrid from '@/routes/Audit/components/PageContentGrid';
import SearchFormWrapper from '@/routes/Audit/components/SearchForm/SearchFormWrapper';
import AdminAuditEventVolumesGraph from '../components/AdminAuditEventVolumesGraph';
import AdminSecurityAuditEventsSearchForm from './AdminSecurityAuditEventsSearchForm';
import AdminSecurityAuditEvents from './AdminSecurityAuditEvents';

const AdminSecurityAuditEventsWithSearchForm = () => {
  const [securityAuditEventsSearchInput, setSecurityAuditEventsSearchInput] =
    useState<AdminSecurityAuditEventsSearchInput>({});

  return (
    <PageContentGrid>
      <SearchFormWrapper>
        <AdminSecurityAuditEventsSearchForm
          lastSearchInput={securityAuditEventsSearchInput}
          onSearch={securityAuditEventsSearchInput => {
            setSecurityAuditEventsSearchInput(securityAuditEventsSearchInput);
          }}
        />
        <AdminAuditEventVolumesGraph />
      </SearchFormWrapper>
      <AdminSecurityAuditEvents searchInput={securityAuditEventsSearchInput} />
    </PageContentGrid>
  );
};

export default AdminSecurityAuditEventsWithSearchForm;
