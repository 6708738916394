import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { AdminSecurityAuditEventType } from '../enums';
import Space from '@/components/Space';
import React, { useState } from 'react';
import { AdminSecurityAuditEventsSearchInput } from '../types';
import { Moment } from 'moment';
import SearchForm, {
  SearchFormGroup,
  SearchFormGroupTitle,
  SearchFormGroupContent,
  SearchButton,
} from '@/routes/Audit/components/SearchForm';
import SearchFormDateRange from '@/routes/Audit/components/SearchForm/SearchFormDateRange';
import {
  isDateRangeValid,
  toEndOfDay,
} from '@/routes/Audit/components/SearchForm/SearchFormDateRange/dateRangeUtils';
import NoneMenuItem from '@/routes/Audit/components/SearchForm/NoneMenuItem';

type AdminSecurityAuditEventsSearchFormProps = {
  lastSearchInput: AdminSecurityAuditEventsSearchInput;
  onSearch: (searchInput: AdminSecurityAuditEventsSearchInput) => void;
};

const AdminSecurityAuditEventsSearchForm = ({
  lastSearchInput,
  onSearch,
}: AdminSecurityAuditEventsSearchFormProps) => {
  const [eventType, setEventType] = useState<AdminSecurityAuditEventType | ''>(
    '',
  );
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [originIp, setOriginIp] = useState('');
  const [superUserNameOrEmail, setSuperUserNameOrEmail] = useState('');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const handleSearch = (event: React.FormEvent): void => {
    event.preventDefault();

    const searchInput = {
      eventType: eventType || undefined,
      additionalInfo: additionalInfo.trim() || undefined,
      originIp: originIp.trim() || undefined,
      superUserNameOrEmail: superUserNameOrEmail.trim() || undefined,
      startDate: startDate?.toDate(),
      endDate: toEndOfDay(endDate)?.toDate(),
    };

    if (JSON.stringify(lastSearchInput) === JSON.stringify(searchInput)) {
      return;
    }

    onSearch(searchInput);
  };

  return (
    <SearchForm onSubmit={handleSearch}>
      <FormControl variant="filled" size="small" fullWidth>
        <InputLabel id="audit-event-type-select-label">Event type</InputLabel>
        <Select<AdminSecurityAuditEventType>
          labelId="audit-event-type-select-label"
          id="audit-event-type-select"
          label="Event type"
          value={eventType}
          onChange={e =>
            setEventType(e.target.value as AdminSecurityAuditEventType | '')
          }
        >
          <NoneMenuItem value="">None</NoneMenuItem>
          {Object.values(AdminSecurityAuditEventType).map(
            securityAuditEventType => (
              <MenuItem
                value={securityAuditEventType}
                key={securityAuditEventType}
              >
                {securityAuditEventType}
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>
      <Space height={12} />
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="additional-specification">
          Additional specification
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <TextField
            variant="filled"
            size="small"
            label="additionalInfo JSON"
            value={additionalInfo}
            onChange={e => setAdditionalInfo(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="user">User</SearchFormGroupTitle>
        <SearchFormGroupContent>
          <TextField
            variant="filled"
            size="small"
            label="User name or e-mail address"
            value={superUserNameOrEmail}
            onChange={e => setSuperUserNameOrEmail(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label="IP address"
            value={originIp}
            onChange={e => setOriginIp(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="date-range">
          Date range
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <SearchFormDateRange
            startDate={startDate}
            onChangeStartDate={date => setStartDate(date)}
            endDate={endDate}
            onChangeEndDate={date => setEndDate(date)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchButton disabled={!isDateRangeValid(startDate, endDate)} />
    </SearchForm>
  );
};

export default AdminSecurityAuditEventsSearchForm;
