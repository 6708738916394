import { useEffect, useState } from 'react';
import { AuditEventsSearchInput } from '../../types';
import api from '@/lib/api';
import CountTextWrapper from '../../components/Events/CountTextWrapper';
import CountTextPlaceholder from '../../components/Events/CountTextPlaceholder';

type AuditEventCountProps = {
  searchInput: AuditEventsSearchInput;
  shownAuditEventCount: number;
};

type AuditEventCounts = {
  totalCount: number;
  filteredCount: number;
};

const AuditEventCount = ({
  searchInput,
  shownAuditEventCount,
}: AuditEventCountProps) => {
  const [counts, setCounts] = useState<AuditEventCounts | null>(null);

  useEffect(() => {
    setCounts(null);

    api
      .post<AuditEventCounts>('audit-events/count', searchInput)
      .then(auditEventCounts => {
        setCounts(auditEventCounts);
      });
  }, [searchInput]);

  if (counts === null) {
    // This placeholder prevents page jumping when the count request completes
    return <CountTextPlaceholder />;
  }

  if (counts.totalCount === counts.filteredCount) {
    return (
      <CountTextWrapper>
        {counts.totalCount} total audit events ({shownAuditEventCount} shown)
      </CountTextWrapper>
    );
  }

  return (
    <CountTextWrapper>
      Search matched {counts.filteredCount} of {counts.totalCount} audit events
      ({shownAuditEventCount} shown)
    </CountTextWrapper>
  );
};

export default AuditEventCount;
