import { SchoolWithStats } from '../../types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Alert } from '@mui/material';
import useSwr from '@/lib/useSwr';
import { InstanceConfig } from '../../types';
import InstanceConfigEditor from './InstanceConfigEditor';
import Card from '@/components/Card';

type InstanceConfigManagerProps = {
  school: SchoolWithStats;
};

const InstanceConfigManager = ({ school }: InstanceConfigManagerProps) => {
  const {
    isLoading,
    data: instanceConfig,
    error,
  } = useSwr<InstanceConfig>(`/schools/${school.id}/instance-config`);

  return (
    <Card>
      <Card.Title>Instance configuration</Card.Title>
      {isLoading && <LoadingSpinner margin={32} centered />}
      {!isLoading && error && (
        <Alert severity="error">
          Failed to load instance configuration for this school.
        </Alert>
      )}
      {!isLoading && instanceConfig && (
        <InstanceConfigEditor school={school} instanceConfig={instanceConfig} />
      )}
    </Card>
  );
};

export default InstanceConfigManager;
