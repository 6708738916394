import { useEffect, useState } from 'react';
import { SecurityAuditEventsSearchInput } from '../../types';
import api from '@/lib/api';
import CountTextWrapper from '../../components/Events/CountTextWrapper';
import CountTextPlaceholder from '../../components/Events/CountTextPlaceholder';

type SecurityAuditEventCountProps = {
  searchInput: SecurityAuditEventsSearchInput;
  shownSecurityAuditEventCount: number;
};

type SecurityAuditEventCounts = {
  totalCount: number;
  filteredCount: number;
};

const SecurityAuditEventCount = ({
  searchInput,
  shownSecurityAuditEventCount,
}: SecurityAuditEventCountProps) => {
  const [counts, setCounts] = useState<SecurityAuditEventCounts | null>(null);

  useEffect(() => {
    setCounts(null);

    api
      .post<SecurityAuditEventCounts>(
        'security-audit-events/count',
        searchInput,
      )
      .then(securityAuditEventCounts => {
        setCounts(securityAuditEventCounts);
      });
  }, [searchInput]);

  if (counts === null) {
    // This placeholder prevents page jumping when the count request completes
    return <CountTextPlaceholder />;
  }

  if (counts.totalCount === counts.filteredCount) {
    return (
      <CountTextWrapper>
        {counts.totalCount} total security events (
        {shownSecurityAuditEventCount} shown)
      </CountTextWrapper>
    );
  }

  return (
    <CountTextWrapper>
      Search matched {counts.filteredCount} of {counts.totalCount} security
      events ({shownSecurityAuditEventCount} shown)
    </CountTextWrapper>
  );
};

export default SecurityAuditEventCount;
