import { useParams } from 'react-router-dom';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Alert } from '@mui/material';
import AccountSetupFlow from './AccountSetupFlow';
import useSwr from '@/lib/useSwr';
import AppTitle from '@/components/AppTitle';

type AccountSetupQueryParams = {
  token: string;
};

type InviteTokenInfoResponse =
  | { exists: false }
  | {
      exists: true;
      superUser: {
        firstName: string | null;
      };
    };

const AccountSetup = () => {
  const { token } = useParams() as AccountSetupQueryParams;
  const {
    isLoading,
    data: inviteTokenInfo,
    error,
  } = useSwr<InviteTokenInfoResponse>(`auth/setup/invite-token/${token}`);

  return (
    <>
      <AppTitle>Set up your account</AppTitle>
      {isLoading && <LoadingSpinner margin={32} centered />}
      {!isLoading && inviteTokenInfo?.exists && (
        <AccountSetupFlow
          firstName={inviteTokenInfo.superUser.firstName}
          inviteToken={token}
        />
      )}
      {!isLoading && !error && !inviteTokenInfo?.exists && (
        <Alert severity="error">Your invite token could not be found.</Alert>
      )}
      {!isLoading && !!error && (
        <Alert severity="error">
          Something went wrong while trying to load your invite.
        </Alert>
      )}
    </>
  );
};

export default AccountSetup;
