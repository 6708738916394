import React from 'react';

const applyPropStripping = (
  originalProps: Record<string, unknown>,
  strippedProps: string[],
) => {
  const filteredProps = { ...originalProps };

  for (const strippedProp of strippedProps) {
    delete filteredProps[strippedProp];
  }

  return filteredProps;
};

const stripProps = (
  Component: React.ElementType,
  ...strippedProps: string[]
) => {
  function StrippedPropsComponent<Props extends object>(props: Props) {
    const filteredProps = applyPropStripping(
      props as Record<string, unknown>,
      strippedProps,
    );

    return <Component {...filteredProps} />;
  }

  StrippedPropsComponent.displayName = `stripProps(${
    // @ts-expect-error: TypeScript does not recognise presence of
    // displayName/name properties, but these are always present.
    Component.displayName ?? Component.name
  })`;

  return StrippedPropsComponent;
};

export default stripProps;
