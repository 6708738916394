import useSyncedState from '@/lib/useSyncedState';
import { PerspectiveCohort, PerspectiveCohortWithPerspectives } from '../types';
import TextFieldWithEscListener from '@/components/TextFieldWithEscListener';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Check as SaveIcon } from '@mui/icons-material';
import useSwrMutate from '@/lib/useSwrMutate';
import api from '@/lib/api';

type PerspectiveCohortNameEditorProps = {
  perspectiveCohort: PerspectiveCohort;
  onClose: () => void;
};

const StyledForm = styled('form')({
  // `height: 42` is necessary to make sure the form occupies as much height as
  // the title section normally does, to prevent the interface "jumping"
  height: 42,
  display: 'flex',
  alignItems: 'center',
});

const StyledTextField = styled(TextFieldWithEscListener)({
  marginRight: 12,
  '.MuiInputBase-root': {
    fontSize: 28,
    fontWeight: 500,
  },
});

const PerspectiveCohortNameEditor = ({
  perspectiveCohort,
  onClose,
}: PerspectiveCohortNameEditorProps) => {
  const [name, setName] = useSyncedState(perspectiveCohort.name);
  const [saving, setSaving] = useState(false);

  const mutate = useSwrMutate<PerspectiveCohortWithPerspectives>(
    `/perspectives/cohorts/${perspectiveCohort.id}`,
  );

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    setSaving(true);

    const updatedPerspectiveCohort = await api.put<PerspectiveCohort>(
      `/perspectives/cohorts/${perspectiveCohort.id}/name`,
      {
        name,
      },
    );

    await mutate(perspectiveCohort => {
      if (typeof perspectiveCohort === 'undefined') {
        return;
      }

      return {
        ...perspectiveCohort,
        ...updatedPerspectiveCohort,
      };
    });

    setSaving(false);
    onClose();
  };

  return (
    <StyledForm onSubmit={handleSave}>
      <StyledTextField
        variant="outlined"
        placeholder="name"
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
        onEscPress={onClose}
        size="small"
      />
      <IconButton type="submit" disabled={name.trim().length === 0 || saving}>
        <SaveIcon />
      </IconButton>
    </StyledForm>
  );
};

export default PerspectiveCohortNameEditor;
