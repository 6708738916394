import { DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import DialogActions from './DialogActions';
import { DialogProps as MuiDialogProps } from '@mui/material';
import DialogWithDangerousHandling from './DialogWithDangerousHandling';
import ResponsiveDialogPaper from './ResponsiveDialogPaper';

type DialogProps = MuiDialogProps & {
  open: boolean;
  dangerous?: boolean;
};

const Dialog = (props: DialogProps) => (
  <DialogWithDangerousHandling
    PaperComponent={ResponsiveDialogPaper}
    fullWidth
    {...props}
  />
);

Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.ContentText = DialogContentText;
Dialog.Actions = DialogActions;

export default Dialog;
