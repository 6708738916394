import styled from '@emotion/styled';
import GraphWrapper from './InnerAuditEventVolumesGraph/GraphWrapper';
import LoadingSpinner from '@/components/LoadingSpinner';

const LoadingMessageWrapper = styled(GraphWrapper)({
  paddingLeft: 20,
  paddingRight: 20,
  height: 234,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  p: {
    marginTop: 12,
    marginBottom: 0,
    fontFamily: 'CircularStd',
    textAlign: 'center',
  },
});

const GraphLoadingPlaceholder = () => (
  <LoadingMessageWrapper>
    <LoadingSpinner />
    <p>Loading event graph</p>
  </LoadingMessageWrapper>
);

export default GraphLoadingPlaceholder;
