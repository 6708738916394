import React, { createContext } from 'react';
import { SchoolSummary } from '../schools/types';
import useApiResponse from '@/lib/useApiResponse';

const SchoolsContext = createContext<Record<number, SchoolSummary> | null>(
  null,
);

type AutomatedSchoolsContextProviderProps = {
  children: React.ReactNode;
};

export const AutomatedSchoolsContextProvider = ({
  children,
}: AutomatedSchoolsContextProviderProps) => {
  const [loading, schools] =
    useApiResponse<Record<number, SchoolSummary>>('schools/summaries');

  return (
    <SchoolsContext.Provider value={loading ? null : schools}>
      {children}
    </SchoolsContext.Provider>
  );
};

export default SchoolsContext;
