export const UNKNOWN_ERROR_ERROR_CODE = 'api/unknown';

export default class ApiError extends Error {
  private readonly responseContent: unknown;

  constructor(responseContent: unknown) {
    super(`API request failed: ${JSON.stringify(responseContent)}`);

    this.responseContent = responseContent;
  }

  getErrorCode(): string {
    if (
      typeof this.responseContent !== 'object' ||
      this.responseContent === null ||
      !('message' in this.responseContent)
    ) {
      return UNKNOWN_ERROR_ERROR_CODE;
    }

    const message = this.responseContent.message;

    if (typeof message === 'undefined' || message === null) {
      return UNKNOWN_ERROR_ERROR_CODE;
    }

    if (typeof message !== 'string') {
      return message.toString();
    }

    return message;
  }
}
