import useAuthenticatedSuperUser from '@/components/AuthenticationState/useAuthenticatedSuperUser';
import RandomColorAvatar from '@/components/RandomColorAvatar';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import HeaderMenu from './HeaderMenu';
import { ManageAccounts as ProfileSettingsIcon } from '@mui/icons-material';
import SignoutMenuItem from './SignoutMenuItem';
import { Link, useLocation } from 'react-router-dom';

const HeaderUserMenu = () => {
  const authenticatedSuperUser = useAuthenticatedSuperUser()!;

  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorElement(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorElement(null);
  };

  const location = useLocation();

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <>
      <IconButton onClick={openMenu}>
        <RandomColorAvatar size={32}>
          {authenticatedSuperUser.profile.firstName.charAt(0)}
        </RandomColorAvatar>
      </IconButton>
      <HeaderMenu
        open={menuAnchorElement !== null}
        anchorEl={menuAnchorElement}
        onClose={closeMenu}
      >
        <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <ProfileSettingsIcon />
          </ListItemIcon>
          <ListItemText>Manage profile</ListItemText>
        </MenuItem>
        <Divider />
        <SignoutMenuItem />
      </HeaderMenu>
    </>
  );
};

export default HeaderUserMenu;
