import Space from '@/components/Space';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Alert, Button } from '@mui/material';
import PushToRight from '@/components/PushToRight';
import QrCode from './QrCode';
import FlexWrapper from '@/components/FlexWrapper';
import useSwr from '@/lib/useSwr';

type MfaQrCodeProps = {
  inviteToken: string;
  onDone: () => void;
};

type MfaSetupResponse = {
  qrCode: string;
};

const MfaQrCode = ({ inviteToken, onDone }: MfaQrCodeProps) => {
  const { isLoading, data, error } = useSwr<MfaSetupResponse>(
    `auth/setup/mfa?inviteToken=${inviteToken}`,
  );

  return (
    <>
      <h1>Secure your account</h1>
      <p>
        Scan the QR code below using the Authenticator app to set up
        Multi-Factor Authentication for your account.
      </p>
      <Space height={24} />
      {isLoading && (
        <LoadingSpinner marginTop={56} marginBottom={32} centered />
      )}
      {!isLoading && !!error && (
        <Alert severity="error">
          Something went wrong while trying to retrieve your MFA QR code.
        </Alert>
      )}
      {!isLoading && data && (
        <>
          <FlexWrapper>
            <QrCode src={data.qrCode} />
          </FlexWrapper>
          <PushToRight>
            <Button variant="contained" onClick={onDone} disableElevation>
              Next
            </Button>
          </PushToRight>
        </>
      )}
    </>
  );
};

export default MfaQrCode;
