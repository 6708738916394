import { useState } from 'react';
import { SecurityAuditEventsSearchInput } from '../types';
import SecurityAuditEventsSearchForm from './SecurityAuditEventsSearchForm';
import SecurityAuditEvents from './SecurityAuditEvents';
import PageContentGrid from '../components/PageContentGrid';
import SearchFormWrapper from '../components/SearchForm/SearchFormWrapper';
import AuditEventVolumesGraph from '../components/AuditEventVolumesGraph';

const SecurityAuditEventsWithSearchForm = () => {
  const [securityAuditEventsSearchInput, setSecurityAuditEventsSearchInput] =
    useState<SecurityAuditEventsSearchInput>({});

  return (
    <PageContentGrid>
      <SearchFormWrapper>
        <SecurityAuditEventsSearchForm
          lastSearchInput={securityAuditEventsSearchInput}
          onSearch={securityAuditEventsSearchInput => {
            setSecurityAuditEventsSearchInput(securityAuditEventsSearchInput);
          }}
        />
        <AuditEventVolumesGraph />
      </SearchFormWrapper>
      <SecurityAuditEvents searchInput={securityAuditEventsSearchInput} />
    </PageContentGrid>
  );
};

export default SecurityAuditEventsWithSearchForm;
