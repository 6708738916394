import React, { useState } from 'react';
import AddButton from './AddButton';
import FormWrapper from './FormWrapper';
import AllowedEmailTextField from '../AllowedEmailTextField';
import { IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import { AllowedEmail } from '@/routes/schools/types';
import { AllowedEmailsWithId } from '../../useAllowedEmailsState';

type AddAllowedEmailProps = {
  allowedEmails: AllowedEmailsWithId;
  onAdd: (allowedEmail: AllowedEmail) => void;
};

const AddAllowedEmail = ({
  allowedEmails,
  onAdd,
  ...props
}: AddAllowedEmailProps) => {
  const [formOpen, setFormOpen] = useState(false);
  const [newAllowedEmailPrefix, setNewAllowedEmailPrefix] = useState('');
  const [newAllowedEmailDomain, setNewAllowedEmailDomain] = useState('');
  const [showEmailAlreadyAddedError, setShowEmailAlreadyAddedError] =
    useState(false);

  if (!formOpen) {
    return <AddButton onClick={() => setFormOpen(true)} {...props} />;
  }

  const saveNewAllowedEmail = (e: React.FormEvent) => {
    e.preventDefault();

    const alreadyAddedAllowedEmails = allowedEmails.map(({ allowedEmail }) =>
      JSON.stringify(allowedEmail),
    );

    const newAllowedEmailAsString = JSON.stringify([
      newAllowedEmailPrefix,
      newAllowedEmailDomain,
    ]);

    if (alreadyAddedAllowedEmails.includes(newAllowedEmailAsString)) {
      setShowEmailAlreadyAddedError(true);
      return;
    }

    onAdd([newAllowedEmailPrefix, newAllowedEmailDomain]);

    setFormOpen(false);
    setNewAllowedEmailPrefix('');
    setNewAllowedEmailDomain('');
    setShowEmailAlreadyAddedError(false);
  };

  return (
    <FormWrapper onSubmit={saveNewAllowedEmail}>
      <AllowedEmailTextField
        value={newAllowedEmailPrefix}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewAllowedEmailPrefix(e.target.value.trim())
        }
        asPrefixField
      />
      <Space width={8} />
      <AllowedEmailTextField
        value={newAllowedEmailDomain}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewAllowedEmailDomain(e.target.value.trim())
        }
        error={showEmailAlreadyAddedError}
        helperText={
          showEmailAlreadyAddedError &&
          "You can't add the same e-mail domain twice"
        }
        autoFocus
      />
      <IconButton type="submit" disabled={!newAllowedEmailDomain}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default AddAllowedEmail;
