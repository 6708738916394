import { SchoolDataMigration } from '../data-migrations/types';

export type SchoolSummary = {
  id: number;
  reference: string;
  displayName: string;
  createdAt: string;
};

export type School = SchoolSummary & {
  _count: {
    users: number;
  };
};

export type SchoolWithStats = School & {
  dataMigrations: SchoolDataMigration[];
  _count: {
    users: number;
    challenges: number;
    tasks: number;
  };
};

export enum IdentityConnectionProvider {
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export type AllowedEmail = [string, string];

export type InstanceConfig = {
  allowedEmails: AllowedEmail[];
  authProvider: IdentityConnectionProvider;
  enablePerspectiveModule: boolean;
  useReversedSliders: boolean;
  enableUserSwitcherArrows: boolean;
  useProfilePictures: boolean;
  enableAggressiveRubricCollapsing: boolean;
  customChallengeNaming: {
    enabled: boolean;
    customChallengeNameSingular?: string;
    customChallengeNamePlural?: string;
  };
};
