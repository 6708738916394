/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

const useSyncedState = <T>(
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [JSON.stringify(initialValue)]);

  return [value, setValue];
};

export default useSyncedState;
