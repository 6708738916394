import React, { useState } from 'react';
import PushToRight from '@/components/PushToRight';
import Space from '@/components/Space';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import api from '@/lib/api';
import validatePassword from '@/lib/validation/validatePassword';
import validatePasswordConfirmation from '@/lib/validation/validatePasswordConfirmation';

type PasswordSetupProps = {
  firstName: string | null;
  inviteToken: string;
  onFinish: () => void;
};

type PasswordErrors = {
  password: string | null;
  passwordConfirmation: string | null;
};

const PasswordSetup = ({
  firstName,
  inviteToken,
  onFinish,
}: PasswordSetupProps) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>({
    password: null,
    passwordConfirmation: null,
  });

  const [saving, setSaving] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const passwordErrors = {
      password: validatePassword(password),
      passwordConfirmation: validatePasswordConfirmation(
        password,
        passwordConfirmation,
      ),
    };

    setPasswordErrors(passwordErrors);

    if (
      passwordErrors.password !== null ||
      passwordErrors.passwordConfirmation !== null
    ) {
      return;
    }

    setSaving(true);

    await api.post('/auth/setup/password', {
      inviteToken,
      password,
    });

    setSaving(false);

    onFinish();
  };

  return (
    <>
      <h1>{firstName !== null ? `Welcome, ${firstName}` : 'Welcome!'}</h1>
      <p>Enter the password you want to use below.</p>
      <Space height={24} />
      <form onSubmit={handleSubmit}>
        <TextField
          variant="filled"
          name="password"
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={passwordErrors.password !== null}
          helperText={passwordErrors.password}
          type="password"
          fullWidth
        />
        <Space height={12} />
        <TextField
          variant="filled"
          name="password_confirmation"
          label="Password confirmation"
          value={passwordConfirmation}
          onChange={e => setPasswordConfirmation(e.target.value)}
          error={passwordErrors.passwordConfirmation !== null}
          helperText={passwordErrors.passwordConfirmation}
          type="password"
          fullWidth
        />
        <Space height={12} />
        <PushToRight>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={saving}
            disableElevation
          >
            Next
          </LoadingButton>
        </PushToRight>
      </form>
    </>
  );
};

export default PasswordSetup;
