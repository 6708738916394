import { Moment } from 'moment';
import { isEarlier, isLater } from './dateUtils';

const datesAreEqual = (dateOne: Moment, dateTwo: Moment): boolean => {
  const firstMoment = dateOne.clone().startOf('day');
  const secondMoment = dateTwo.clone().startOf('day');

  return firstMoment.isSame(secondMoment);
};

export const isDateRangeValid = (
  startDate: Moment | null,
  endDate: Moment | null,
): boolean => {
  if (startDate === null && endDate === null) {
    return true;
  }

  if (startDate !== null && !startDate.isValid()) {
    return false;
  }

  if (endDate !== null && !endDate.isValid()) {
    return false;
  }

  if (startDate === null || endDate === null) {
    return true;
  }

  if (datesAreEqual(startDate, endDate)) {
    return true;
  }

  return isEarlier(startDate, endDate) && isLater(endDate, startDate);
};

export const toEndOfDay = (date: Moment | null): Moment | null => {
  if (date === null) {
    return null;
  }

  return date.clone().endOf('day');
};
