import SuperUserInfoTableCell from '@/routes/AdminAudit/components/SuperUserInfoTableCell';
import { AdminAuditEvent } from '@/routes/AdminAudit/types';
import AuditTable, {
  HeadTableCell,
  MissingTableCellValue,
  SecondaryTableCell,
} from '@/routes/Audit/components/Events/AuditTable';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import AdminDetailsTableCell from './AdminDetailsTableCell';
import { useState } from 'react';

type AdminAuditEventTableProps = {
  auditEvents: AdminAuditEvent[];
};

const AdminAuditEventTable = ({ auditEvents }: AdminAuditEventTableProps) => {
  const [openAuditEvent, setOpenAuditEvent] = useState<number | null>(null);

  return (
    <AuditTable>
      <TableHead>
        <TableRow>
          <HeadTableCell width={50}>ID</HeadTableCell>
          <HeadTableCell width={100}>Event type</HeadTableCell>
          <HeadTableCell width={100}>Model type</HeadTableCell>
          <HeadTableCell width={70}>Model ID</HeadTableCell>
          <HeadTableCell width={150}>Model name</HeadTableCell>
          <HeadTableCell>Event subtype</HeadTableCell>
          <HeadTableCell width={150}>User</HeadTableCell>
          <HeadTableCell width={120}>Created at</HeadTableCell>
          <HeadTableCell width={24}></HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {auditEvents.map(auditEvent => (
          <TableRow
            selected={auditEvent.id === openAuditEvent}
            key={auditEvent.id}
          >
            <TableCell component="th">#{auditEvent.id}</TableCell>
            <TableCell>{auditEvent.eventType}</TableCell>
            <TableCell>
              <span title={auditEvent.modelType}>{auditEvent.modelType}</span>
            </TableCell>
            <TableCell>
              <code>{auditEvent.modelId}</code>
            </TableCell>
            <TableCell>
              {auditEvent.modelName ? (
                auditEvent.modelName
              ) : (
                <MissingTableCellValue>(unknown)</MissingTableCellValue>
              )}
            </TableCell>
            <TableCell>
              {auditEvent.eventSubtype ? (
                <code>{auditEvent.eventSubtype}</code>
              ) : (
                <MissingTableCellValue>(unspecified)</MissingTableCellValue>
              )}
            </TableCell>
            <SuperUserInfoTableCell auditEvent={auditEvent} />
            <SecondaryTableCell>
              {moment(auditEvent.createdAt).format('L LT')}
            </SecondaryTableCell>
            <AdminDetailsTableCell
              auditEvent={auditEvent}
              onOpen={() => setOpenAuditEvent(auditEvent.id)}
              onClose={() => setOpenAuditEvent(null)}
            />
          </TableRow>
        ))}
      </TableBody>
    </AuditTable>
  );
};

export default AdminAuditEventTable;
