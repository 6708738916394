import AppTitle from '@/components/AppTitle';
import MainPageTitle from '@/components/MainPageTitle';
import PushToRight from '@/components/PushToRight';
import Space from '@/components/Space';
import api from '@/lib/api';
import ApiError from '@/lib/api/ApiError';
import validateUrl from '@/lib/validation/validateUrl';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Card from '@/components/Card';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import useSwr from '@/lib/useSwr';
import { School } from '../schools/types';
import LoadingSpinner from '@/components/LoadingSpinner';
import NoneMenuItem from '../Audit/components/SearchForm/NoneMenuItem';

type DataMigrationErrors = {
  schoolId: string | null;
  legacyDatabaseUrl: string | null;
  legacyDatabaseSecret: string | null;
};

const StyledSelect = styled(Select)({
  minWidth: 240,
  maxWidth: 240,
}) as unknown as typeof Select;

const CreateDataMigration = () => {
  const { isLoading, data: schools, error } = useSwr<School[]>('/schools');

  const [schoolId, setSchoolId] = useState<number | ''>('');
  const [legacyDatabaseUrl, setLegacyDatabaseUrl] = useState('');
  const [legacyDatabaseSecret, setLegacyDatabaseSecret] = useState('');

  const [dataMigrationErrors, setDataMigrationErrors] =
    useState<DataMigrationErrors>({
      schoolId: null,
      legacyDatabaseUrl: null,
      legacyDatabaseSecret: null,
    });

  const [saving, setSaving] = useState(false);
  const [genericSaveError, setGenericSaveError] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleSave = async (): Promise<void> => {
    setGenericSaveError(null);

    const dataMigrationErrors = {
      schoolId: schoolId === '' ? 'Please select a school' : null,
      legacyDatabaseUrl: validateUrl(legacyDatabaseUrl),
      legacyDatabaseSecret:
        legacyDatabaseSecret.trim().length === 0
          ? 'Please enter a database secret'
          : null,
    };

    setDataMigrationErrors(dataMigrationErrors);

    if (
      dataMigrationErrors.schoolId !== null ||
      dataMigrationErrors.legacyDatabaseUrl !== null ||
      dataMigrationErrors.legacyDatabaseSecret !== null
    ) {
      return;
    }

    setSaving(true);

    try {
      await api.post('/data-migrations', {
        schoolId,
        legacyDatabaseUrl,
        legacyDatabaseSecret,
      });

      navigate('/data-migrations');
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setGenericSaveError(error.getErrorCode());
    } finally {
      setSaving(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner margin={48} centered />;
  }

  if (error || !schools) {
    return (
      <Alert severity="error">
        Something went wrong while trying to load schools.
      </Alert>
    );
  }

  return (
    <>
      <AppTitle>New data migration</AppTitle>
      <MainPageTitle>New data migration</MainPageTitle>
      {genericSaveError !== null && (
        <>
          <Alert severity="error">
            Could not save school with error code: {genericSaveError}. Please
            try again later.
          </Alert>
          <Space height={24} />
        </>
      )}
      <Card>
        <Card.Title>Target school</Card.Title>
        <FormControl error={dataMigrationErrors.schoolId !== null}>
          <StyledSelect
            value={schoolId}
            onChange={(e: SelectChangeEvent<number>) =>
              setSchoolId(e.target.value as number | '')
            }
            size="small"
            displayEmpty
          >
            <NoneMenuItem value="">None</NoneMenuItem>
            {schools.map(school => (
              <MenuItem value={school.id} key={school.id}>
                {school.displayName}
              </MenuItem>
            ))}
          </StyledSelect>
          {dataMigrationErrors.schoolId !== null && (
            <FormHelperText>{dataMigrationErrors.schoolId}</FormHelperText>
          )}
        </FormControl>
      </Card>
      <Card>
        <Card.Title>Legacy database information</Card.Title>
        <Card.TextField
          label="Legacy database URL"
          name="legacy_database_url"
          value={legacyDatabaseUrl}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLegacyDatabaseUrl(e.target.value)
          }
          error={dataMigrationErrors.legacyDatabaseUrl !== null}
          helperText={dataMigrationErrors.legacyDatabaseUrl}
          disabled={saving}
          width="50%"
        />
        <Space height={12} />
        <Card.TextField
          label="Legacy database secret"
          name="legacy_database_secret"
          value={legacyDatabaseSecret}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLegacyDatabaseSecret(e.target.value)
          }
          type="password"
          error={dataMigrationErrors.legacyDatabaseSecret !== null}
          helperText={dataMigrationErrors.legacyDatabaseSecret}
          disabled={saving}
          width="50%"
        />
      </Card>
      <PushToRight>
        <LoadingButton
          variant="contained"
          onClick={handleSave}
          loading={saving}
        >
          Create
        </LoadingButton>
      </PushToRight>
    </>
  );
};

export default CreateDataMigration;
