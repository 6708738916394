import React, { useRef } from 'react';
import useForceUpdate from 'use-force-update';
import useKeydownListener from '@/lib/useKeydownListener';
import { TextField, TextFieldProps } from '@mui/material';

type TextFieldWithEscListenerProps = TextFieldProps & {
  onEscPress: (event: KeyboardEvent) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, HTMLElement>) => void;
};

const TextFieldWithEscListener = ({
  onEscPress,
  onFocus,
  ...props
}: TextFieldWithEscListenerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const forceUpdate = useForceUpdate();

  useKeydownListener(inputRef.current, (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onEscPress(event);
    }
  });

  return (
    <TextField
      {...props}
      inputRef={inputRef}
      onFocus={(event: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
        // We need to force-update here to force the keydown listener to
        // "refresh". If we don't do this, it won't work until the user has
        // changed the input value
        forceUpdate();

        if (typeof onFocus === 'function') {
          onFocus(event);
        }
      }}
    />
  );
};

export default TextFieldWithEscListener;
