/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import api from './api';

const useApiResponse = <T>(url: string | null): [true, null] | [false, T] => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<T | null>(null);

  const fetchApiResponse = async (): Promise<void> => {
    if (url === null) {
      return;
    }

    setLoading(true);

    const response = await api.get<T>(url);

    setResponse(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchApiResponse();
  }, [url]);

  if (loading || response === null) {
    return [true, null];
  }

  return [false, response];
};

export default useApiResponse;
