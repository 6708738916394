import AppTitle from '@/components/AppTitle';
import MainPageTitle from '@/components/MainPageTitle';
import useSwr from '@/lib/useSwr';
import { useParams } from 'react-router-dom';
import { PerspectiveCohortWithPerspectives } from '../types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Alert, IconButton } from '@mui/material';
import PerspectiveManager from './PerspectiveManager';
import PerspectiveCohortContext from './PerspectiveCohortContext';
import { Edit as EditIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import { useState } from 'react';
import PerspectiveCohortNameEditor from './PerspectiveCohortNameEditor';
import PerspectiveCohortPublishing from './PerspectiveCohortPublishing';
import DeletePerspectiveCohort from './DeletePerspectiveCohort';

type PerspectiveManagerParams = {
  id: string;
};

const PerspectiveCohortWithPerspectiveManager = () => {
  const { id } = useParams() as PerspectiveManagerParams;
  const {
    isLoading,
    data: perspectiveCohort,
    error,
  } = useSwr<PerspectiveCohortWithPerspectives>(`/perspectives/cohorts/${id}`);

  const [nameEditorOpen, setNameEditorOpen] = useState(false);

  const pageTitle =
    isLoading || !perspectiveCohort
      ? 'Perspectives'
      : `Perspectives: ${perspectiveCohort.name}`;

  return (
    <>
      <AppTitle>{pageTitle}</AppTitle>
      <MainPageTitle>
        {nameEditorOpen && perspectiveCohort ? (
          <PerspectiveCohortNameEditor
            perspectiveCohort={perspectiveCohort}
            onClose={() => setNameEditorOpen(false)}
          />
        ) : (
          <>
            {pageTitle}
            <Space width={12} />
            <IconButton onClick={() => setNameEditorOpen(true)}>
              <EditIcon />
            </IconButton>
          </>
        )}
        {perspectiveCohort && (
          <>
            <Space width="auto" />
            <PerspectiveCohortPublishing
              perspectiveCohort={perspectiveCohort}
            />
            <Space width={12} />
            <DeletePerspectiveCohort perspectiveCohort={perspectiveCohort} />
          </>
        )}
      </MainPageTitle>
      {isLoading && <LoadingSpinner margin={48} centered />}
      {!isLoading && (error || typeof perspectiveCohort === 'undefined') && (
        <Alert severity="error">Failed to load perspectives.</Alert>
      )}
      {!isLoading && perspectiveCohort && (
        <PerspectiveCohortContext.Provider value={perspectiveCohort}>
          <PerspectiveManager perspectives={perspectiveCohort.perspectives} />
        </PerspectiveCohortContext.Provider>
      )}
    </>
  );
};

export default PerspectiveCohortWithPerspectiveManager;
