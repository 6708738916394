import styled from '@emotion/styled';
import { Button, ButtonProps, alpha } from '@mui/material';

type EventTypeButtonProps = ButtonProps & {
  selected: boolean;
};

const EventTypeButton = styled(Button)<EventTypeButtonProps>(({
  selected,
  theme,
}) => {
  if (selected) {
    return {
      '&&&': {
        color: theme.palette.secondary.main,
        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
        borderColor: alpha(theme.palette.secondary.main, 0.5),
      },
    };
  }
});

export default EventTypeButton;
