import { TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import { AuditEvent } from '../../../types';
import UserInfoTableCell from '../../../components/UserInfoTableCell';
import moment from 'moment';
import DetailsTableCell from './DetailsTableCell';
import AuditTable, {
  HeadTableCell,
  SecondaryTableCell,
  MissingTableCellValue,
} from '@/routes/Audit/components/Events/AuditTable';
import SchoolTableCell from '@/routes/Audit/components/SchoolTableCell';
import { useState } from 'react';

type AuditEventTableProps = {
  auditEvents: AuditEvent[];
};

const AuditEventTable = ({ auditEvents }: AuditEventTableProps) => {
  const [openAuditEvent, setOpenAuditEvent] = useState<number | null>(null);

  return (
    <AuditTable>
      <TableHead>
        <TableRow>
          <HeadTableCell width={50}>ID</HeadTableCell>
          <HeadTableCell width={100}>Event type</HeadTableCell>
          <HeadTableCell width={100}>Model type</HeadTableCell>
          <HeadTableCell width={150}>Model name</HeadTableCell>
          <HeadTableCell>Event subtype</HeadTableCell>
          <HeadTableCell width={150}>User</HeadTableCell>
          <HeadTableCell width={130}>School</HeadTableCell>
          <HeadTableCell width={120}>Created at</HeadTableCell>
          <HeadTableCell width={24}></HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {auditEvents.map(auditEvent => (
          <TableRow
            selected={auditEvent.id === openAuditEvent}
            key={auditEvent.id}
          >
            <TableCell component="th">#{auditEvent.id}</TableCell>
            <TableCell>{auditEvent.eventType}</TableCell>
            <TableCell>
              <span title={auditEvent.modelType}>{auditEvent.modelType}</span>
            </TableCell>
            <TableCell>
              {auditEvent.modelName ? (
                auditEvent.modelName
              ) : (
                <MissingTableCellValue>(unknown)</MissingTableCellValue>
              )}
            </TableCell>
            <TableCell>
              {auditEvent.eventSubtype ? (
                <code>{auditEvent.eventSubtype}</code>
              ) : (
                <MissingTableCellValue>(unspecified)</MissingTableCellValue>
              )}
            </TableCell>
            <UserInfoTableCell auditEvent={auditEvent} />
            <SchoolTableCell auditEvent={auditEvent} />
            <SecondaryTableCell>
              {moment(auditEvent.createdAt).format('L LT')}
            </SecondaryTableCell>
            <DetailsTableCell
              auditEvent={auditEvent}
              onOpen={() => setOpenAuditEvent(auditEvent.id)}
              onClose={() => setOpenAuditEvent(null)}
            />
          </TableRow>
        ))}
      </TableBody>
    </AuditTable>
  );
};

export default AuditEventTable;
