import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const CONTENT_WIDTH = '(100vw - 300px)';
const WRAPPER_WIDTH = '900px';

const WideAppContent = styled('section')({
  marginLeft: `calc(-1 * ((${CONTENT_WIDTH} - ${WRAPPER_WIDTH}) / 2))`,
  marginRight: `calc(-1 * ((${CONTENT_WIDTH} - ${WRAPPER_WIDTH}) / 2))`,
  padding: '24px 36px',
  width: `calc(${CONTENT_WIDTH})`,
  boxSizing: 'border-box',
  [mediaQuery(1310)]: {
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    width: 'auto',
  },
});

export default WideAppContent;
