import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { SecurityAuditEventType } from '../enums';
import Space from '@/components/Space';
import React, { useContext, useState } from 'react';
import { SecurityAuditEventsSearchInput } from '../types';
import { Moment } from 'moment';
import SearchForm, {
  SearchFormGroup,
  SearchFormGroupTitle,
  SearchFormGroupContent,
  SearchButton,
} from '../components/SearchForm';
import {
  isDateRangeValid,
  toEndOfDay,
} from '../components/SearchForm/SearchFormDateRange/dateRangeUtils';
import SearchFormDateRange from '../components/SearchForm/SearchFormDateRange';
import LoadingSpinner from '@/components/LoadingSpinner';
import SchoolsContext from '../SchoolsContext';
import NoneMenuItem from '../components/SearchForm/NoneMenuItem';

type SecurityAuditEventsSearchFormProps = {
  lastSearchInput: SecurityAuditEventsSearchInput;
  onSearch: (searchInput: SecurityAuditEventsSearchInput) => void;
};

const SecurityAuditEventsSearchForm = ({
  lastSearchInput,
  onSearch,
}: SecurityAuditEventsSearchFormProps) => {
  const schools = useContext(SchoolsContext);

  const [eventType, setEventType] = useState<SecurityAuditEventType | ''>('');
  const [schoolId, setSchoolId] = useState<number | ''>('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [originIp, setOriginIp] = useState('');
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const handleSearch = (event: React.FormEvent): void => {
    event.preventDefault();

    const searchInput = {
      eventType: eventType || undefined,
      schoolId: schoolId || undefined,
      additionalInfo: additionalInfo.trim() || undefined,
      originIp: originIp.trim() || undefined,
      userNameOrEmail: userNameOrEmail.trim() || undefined,
      startDate: startDate?.toDate(),
      endDate: toEndOfDay(endDate)?.toDate(),
    };

    if (JSON.stringify(lastSearchInput) === JSON.stringify(searchInput)) {
      return;
    }

    onSearch(searchInput);
  };

  return (
    <SearchForm onSubmit={handleSearch}>
      <FormControl variant="filled" size="small" fullWidth>
        <InputLabel id="audit-event-type-select-label">Event type</InputLabel>
        <Select<SecurityAuditEventType>
          labelId="audit-event-type-select-label"
          id="audit-event-type-select"
          label="Event type"
          value={eventType}
          onChange={e =>
            setEventType(e.target.value as SecurityAuditEventType | '')
          }
        >
          <NoneMenuItem value="">None</NoneMenuItem>
          {Object.values(SecurityAuditEventType).map(securityAuditEventType => (
            <MenuItem
              value={securityAuditEventType}
              key={securityAuditEventType}
            >
              {securityAuditEventType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Space height={12} />
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="school">School</SearchFormGroupTitle>
        <SearchFormGroupContent>
          {schools === null ? (
            <LoadingSpinner margin={12} size={24} color="secondary" centered />
          ) : (
            <FormControl variant="filled" size="small">
              <InputLabel id="school-select-label">School</InputLabel>
              <Select<number>
                labelId="school-select-label"
                id="school-select"
                label="School"
                value={schoolId}
                onChange={e => setSchoolId(e.target.value as number | '')}
              >
                <NoneMenuItem value="">None</NoneMenuItem>
                {Object.values(schools).map(school => (
                  <MenuItem value={school.id} key={school.id}>
                    {school.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="additional-specification">
          Additional specification
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <TextField
            variant="filled"
            size="small"
            label="additionalInfo JSON"
            value={additionalInfo}
            onChange={e => setAdditionalInfo(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="user">User</SearchFormGroupTitle>
        <SearchFormGroupContent>
          <TextField
            variant="filled"
            size="small"
            label="User name or e-mail address"
            value={userNameOrEmail}
            onChange={e => setUserNameOrEmail(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label="IP address"
            value={originIp}
            onChange={e => setOriginIp(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="date-range">
          Date range
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <SearchFormDateRange
            startDate={startDate}
            onChangeStartDate={date => setStartDate(date)}
            endDate={endDate}
            onChangeEndDate={date => setEndDate(date)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchButton disabled={!isDateRangeValid(startDate, endDate)} />
    </SearchForm>
  );
};

export default SecurityAuditEventsSearchForm;
