import { createContext } from 'react';
import { PerspectiveCohortWithPerspectives } from '../types';

// Note that we have to provide this default to allow using a non-null type
const PerspectiveCohortContext =
  createContext<PerspectiveCohortWithPerspectives>({
    id: -1,
    name: '',
    publishAt: null,
    perspectives: [],
  });

export default PerspectiveCohortContext;
