import { Moment } from 'moment';

export const isEarlier = (dateOne: Moment, dateTwo: Moment): boolean => {
  const firstMoment = dateOne.clone().startOf('day');
  const secondMoment = dateTwo.clone().startOf('day');

  return firstMoment.isBefore(secondMoment);
};

export const isLater = (dateOne: Moment, dateTwo: Moment): boolean => {
  const firstMoment = dateOne.clone().startOf('day');
  const secondMoment = dateTwo.clone().startOf('day');

  return firstMoment.isAfter(secondMoment);
};
