import styled from '@emotion/styled';
import Dialog from '@/components/Dialog';

export const UserTableCellContent = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

export const UserDialogTitle = styled(Dialog.Title)({
  display: 'flex',
  alignItems: 'center',
  '.MuiAvatar-root, .MuiCircularProgress-root, .MuiSvgIcon-root': {
    marginRight: 12,
  },
  '.MuiSvgIcon-root': {
    marginRight: 12,
    width: 21,
    height: 21,
  },
});

export const UserRoleIndicator = styled('span')(({ theme }) => ({
  marginLeft: 12,
  padding: '1px 3px',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
  backgroundColor: theme.palette.primary.dark,
  borderRadius: 3,
}));

export const UserDialogContent = styled(Dialog.Content)(({ theme }) => ({
  color: theme.palette.text.secondary,
  p: {
    marginTop: 4,
    marginBottom: 4,
  },
}));

export const UserDeletedMessage = styled('p')(({ theme }) => ({
  color: theme.palette.warning.dark,
  display: 'flex',
  alignItems: 'center',
  '&&&': {
    marginTop: 12,
  },
  '.MuiSvgIcon-root': {
    marginRight: 6,
  },
}));
