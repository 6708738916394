import LoadingContentWrapper from './LoadingContentWrapper';
import LoadingSpinner from '@/components/LoadingSpinner';
import Space from '@/components/Space';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

type HTMLSectionProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type LoadingSpinnerWithTextProps = HTMLSectionProps & {
  noPadding?: boolean;
  children: React.ReactNode;
};

const LoadingSpinnerWithText = ({
  noPadding,
  children,
  ...props
}: LoadingSpinnerWithTextProps) => (
  <LoadingContentWrapper noPadding={!!noPadding} {...props}>
    <LoadingSpinner color="primary" />
    <Space width={18} />
    <h3>{children}</h3>
  </LoadingContentWrapper>
);

export default LoadingSpinnerWithText;
