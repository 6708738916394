import { useState } from 'react';
import PasswordSetup from './PasswordSetup';
import MfaSetup from './MfaSetup';
import AccountSetupComplete from './AccountSetupComplete';

type AccountSetupFlowProps = {
  firstName: string | null;
  inviteToken: string;
};

enum AccountSetupFlowStage {
  PASSWORD,
  MFA,
  DONE,
}

const AccountSetupFlow = ({
  firstName,
  inviteToken,
}: AccountSetupFlowProps) => {
  const [stage, setStage] = useState(AccountSetupFlowStage.PASSWORD);

  if (stage === AccountSetupFlowStage.PASSWORD) {
    return (
      <PasswordSetup
        firstName={firstName}
        inviteToken={inviteToken}
        onFinish={() => setStage(AccountSetupFlowStage.MFA)}
      />
    );
  }

  if (stage === AccountSetupFlowStage.MFA) {
    return (
      <MfaSetup
        inviteToken={inviteToken}
        onFinish={() => setStage(AccountSetupFlowStage.DONE)}
      />
    );
  }

  return <AccountSetupComplete />;
};

export default AccountSetupFlow;
