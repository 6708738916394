import { AuditEvent, AuditEventsSearchInput } from '../../types';
import AuditEventTable from './AuditEventTable';
import AuditEventCount from './AuditEventCount';
import EventsWrapper from '../../components/Events/EventsWrapper';
import NoMatchesMessage from '../../components/Events/NoMatchesMessage';
import LoadMoreButton from '../../components/Events/LoadMoreButton';
import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';
import useAuditEventsState from '../../useAuditEventsState';

type AuditEventsProps = {
  searchInput: AuditEventsSearchInput;
};

const AuditEvents = ({ searchInput }: AuditEventsProps) => {
  const [loading, auditEvents, canLoadMore, loadMoreAuditEvents] =
    useAuditEventsState<AuditEvent>('audit-events', searchInput);

  return (
    <EventsWrapper>
      {loading && auditEvents.length === 0 ? (
        <LoadingSpinnerWithText noPadding>
          Loading audit events
        </LoadingSpinnerWithText>
      ) : (
        <>
          {auditEvents.length === 0 ? (
            <NoMatchesMessage>
              No audit events matched your search
            </NoMatchesMessage>
          ) : (
            <>
              <AuditEventCount
                searchInput={searchInput}
                shownAuditEventCount={auditEvents.length}
              />
              <AuditEventTable auditEvents={auditEvents} />
              {canLoadMore && (
                <LoadMoreButton
                  loading={loading}
                  onClick={loadMoreAuditEvents}
                />
              )}
            </>
          )}
        </>
      )}
    </EventsWrapper>
  );
};

export default AuditEvents;
