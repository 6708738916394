import { TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { SecurityAuditEvent } from '../../types';
import AuditTable, {
  HeadTableCell,
  JsonTableCellContent,
  MissingTableCellValue,
  SecondaryTableCell,
} from '../../components/Events/AuditTable';
import moment from 'moment';
import UserInfoTableCell from '../../components/UserInfoTableCell';
import SchoolTableCell from '../../components/SchoolTableCell';

type SecurityAuditEventTableProps = {
  securityAuditEvents: SecurityAuditEvent[];
};

const SecurityAuditEventTable = ({
  securityAuditEvents,
}: SecurityAuditEventTableProps) => (
  <AuditTable>
    <TableHead>
      <TableRow>
        <HeadTableCell width={50}>ID</HeadTableCell>
        <HeadTableCell width={100}>Event type</HeadTableCell>
        <HeadTableCell>Additional info</HeadTableCell>
        <HeadTableCell width={150}>User</HeadTableCell>
        <HeadTableCell width={130}>School</HeadTableCell>
        <HeadTableCell width={150}>Origin IP</HeadTableCell>
        <HeadTableCell width={120}>Created at</HeadTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {securityAuditEvents.map(securityAuditEvent => (
        <TableRow key={securityAuditEvent.id}>
          <TableCell component="th">#{securityAuditEvent.id}</TableCell>
          <TableCell>{securityAuditEvent.eventType}</TableCell>
          <TableCell>
            {securityAuditEvent.additionalInfo ? (
              <JsonTableCellContent>
                {securityAuditEvent.additionalInfo}
              </JsonTableCellContent>
            ) : (
              <MissingTableCellValue>(unspecified)</MissingTableCellValue>
            )}
          </TableCell>
          <UserInfoTableCell auditEvent={securityAuditEvent} />
          <SchoolTableCell auditEvent={securityAuditEvent} />
          <TableCell>
            <code>{securityAuditEvent.originIp}</code>
          </TableCell>
          <SecondaryTableCell>
            {moment(securityAuditEvent.createdAt).format('L LT')}
          </SecondaryTableCell>
        </TableRow>
      ))}
    </TableBody>
  </AuditTable>
);

export default SecurityAuditEventTable;
