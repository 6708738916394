import { SchoolDataMigration, SchoolDataMigrationStage } from '../../types';
import styled from '@emotion/styled';

type DataMigrationStatusIndicatorProps = {
  dataMigration: SchoolDataMigration;
};

type StatusIndicatorProps = {
  variant?: 'success' | 'error';
};

const StatusIndicator = styled('span')<StatusIndicatorProps>(
  ({ theme, variant }) => ({
    marginLeft: 'auto',
    marginRight: 16,
    fontSize: 14,
    fontWeight: 500,
    alignSelf: 'center',
    color:
      variant === 'success'
        ? theme.palette.success.main
        : variant === 'error'
          ? theme.palette.error.main
          : theme.palette.text.secondary,
  }),
);

const DataMigrationStatusIndicator = ({
  dataMigration,
}: DataMigrationStatusIndicatorProps) => {
  if (dataMigration.failedAt) {
    return <StatusIndicator variant="error">Failed</StatusIndicator>;
  }

  if (dataMigration.finishedAt) {
    return <StatusIndicator variant="success">Finished</StatusIndicator>;
  }

  if (dataMigration.stage === SchoolDataMigrationStage.QUEUED) {
    return <StatusIndicator>Queued</StatusIndicator>;
  }

  return (
    <StatusIndicator>
      Running ({dataMigration.stage.toLowerCase()})
    </StatusIndicator>
  );
};

export default DataMigrationStatusIndicator;
