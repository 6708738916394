export enum AdminAuditableModelType {
  DATA_MIGRATION = 'DATA_MIGRATION',
  INSTANCE_CONFIG_VALUE = 'INSTANCE_CONFIG_VALUE',
  PERSPECTIVE = 'PERSPECTIVE',
  PERSPECTIVE_COHORT = 'PERSPECTIVE_COHORT',
  PERSPECTIVE_SUBLEVEL = 'PERSPECTIVE_SUBLEVEL',
  SCHOOL = 'SCHOOL',
  SUPER_USER = 'SUPER_USER',
}

export enum AdminAuditEventSubtype {
  DATA_MIGRATION_STARTED = 'DATA_MIGRATION_STARTED',
  DATA_MIGRATION_RETRIED = 'DATA_MIGRATION_RETRIED',
  PERSPECTIVE_COHORT_PUBLISH = 'PERSPECTIVE_COHORT_PUBLISH',
  PERSPECTIVE_COHORT_UNPUBLISH = 'PERSPECTIVE_COHORT_UNPUBLISH',
  PERSPECTIVE_ICON_UPLOAD = 'PERSPECTIVE_ICON_UPLOAD',
  PERSPECTIVE_ICON_REMOVE = 'PERSPECTIVE_ICON_REMOVE',
  PROFILE_UPDATE = 'PROFILE_UPDATE',
}

export enum AdminSecurityAuditEventType {
  AUTHENTICATE = 'AUTHENTICATE',
  AUTHENTICATE_PARTIAL = 'AUTHENTICATE_PARTIAL',
  AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  DATA_MIGRATION_STARTED = 'DATA_MIGRATION_STARTED',
  DATA_MIGRATION_RETRIED = 'DATA_MIGRATION_RETRIED',
  INSTANCE_CONFIG_CHANGE = 'INSTANCE_CONFIG_CHANGE',
  SUPER_USER_CREATED = 'SUPER_USER_CREATED',
  SUPER_USER_CREATE_SUSPICIOUS_REQUEST = 'SUPER_USER_CREATE_SUSPICIOUS_REQUEST',
  SUPER_USER_INVITED = 'SUPER_USER_INVITED',
  SUPER_USER_ROLE_CHANGE = 'SUPER_USER_ROLE_CHANGE',
  SUPER_USER_PASSWORD_SETUP_COMPLETE = 'SUPER_USER_PASSWORD_SETUP_COMPLETE',
  SUPER_USER_MFA_SECRET_GENERATED = 'SUPER_USER_MFA_SECRET_GENERATED',
  SUPER_USER_MFA_SETUP_COMPLETE = 'SUPER_USER_MFA_SETUP_COMPLETE',
  SUPER_USER_SETUP_SUSPICIOUS_REQUEST = 'SUPER_USER_SETUP_SUSPICIOUS_REQUEST',
  SUPER_USER_PASSWORD_RESET_SUSPICIOUS_REQUEST = 'SUPER_USER_PASSWORD_RESET_SUSPICIOUS_REQUEST',
  SUPER_USER_PASSWORD_RESET_REQUESTED = 'SUPER_USER_PASSWORD_RESET_REQUESTED',
  SUPER_USER_PASSWORD_RESET = 'SUPER_USER_PASSWORD_RESET',
  SUPER_USER_DELETED = 'SUPER_USER_DELETED',
  SUPER_USER_SUSPICIOUS_REQUEST = 'SUPER_USER_SUSPICIOUS_REQUEST',
  ACCESS_AUDIT_EVENTS = 'ACCESS_AUDIT_EVENTS',
  ACCESS_SECURITY_AUDIT_EVENTS = 'ACCESS_SECURITY_AUDIT_EVENTS',
  ACCESS_ADMIN_AUDIT_EVENTS = 'ACCESS_ADMIN_AUDIT_EVENTS',
  ACCESS_ADMIN_SECURITY_AUDIT_EVENTS = 'ACCESS_ADMIN_SECURITY_AUDIT_EVENTS',
  ACCESS_SUPER_USERS = 'ACCESS_SUPER_USERS',
  ACCESS_SCHOOLS = 'ACCESS_SCHOOLS',
  ACCESS_DATA_MIGRATIONS = 'ACCESS_DATA_MIGRATIONS',
  ROLE_ESCALATION_ATTEMPT = 'ROLE_ESCALATION_ATTEMPT',
  SUSPICIOUS_REQUEST = 'SUSPICIOUS_REQUEST',
}
