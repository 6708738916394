import styled from '@emotion/styled';
import { ButtonBase } from '@mui/material';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import stripProps from '@/lib/stripProps';
import useScrollPerspectiveManagerWrapperToRight from '../useScrollPerspectiveManagerWrapperToRight';

type OpenSublevelsButtonProps = Record<string, unknown> & {
  onClick: () => void;
  asCloseButton: boolean;
  hidden: boolean;
  fullSize?: boolean;
};

const StyledButton = styled(stripProps(ButtonBase, 'fullSize'))<
  Omit<OpenSublevelsButtonProps, 'asCloseButton'>
>(({ theme, hidden, fullSize }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  paddingLeft: 6,
  paddingRight: 6,
  height: fullSize ? '90%' : '60%',
  backgroundColor: theme.palette.action.hover,
  transition: theme.transitions.create('all'),
  display: hidden ? 'none' : undefined,
  ':hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const OpenSublevelsButton = ({
  onClick,
  asCloseButton,
  ...props
}: OpenSublevelsButtonProps) => {
  // TODO this hook is unnecessary
  const scrollPerspectiveManagerWrapperToRight =
    useScrollPerspectiveManagerWrapperToRight();

  const handleClick = () => {
    onClick();

    setTimeout(() => {
      scrollPerspectiveManagerWrapperToRight();
    }, 10);
  };

  return (
    <StyledButton
      className="perspective-card-content__open-sublevels-button"
      onClick={handleClick}
      {...props}
    >
      {asCloseButton ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </StyledButton>
  );
};

export default OpenSublevelsButton;
