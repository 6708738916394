import SuperUserRole from '@/lib/constants/SuperUserRole';
import useSyncedState from '@/lib/useSyncedState';
import { SuperUser } from '@/routes/super-users/types';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import styled from '@emotion/styled';
import api from '@/lib/api';

type RoleSelectProps = {
  superUser: SuperUser;
  disabled: boolean;
};

const StyledSelect = styled(Select)({
  marginRight: 8,
  width: 160,
}) as unknown as typeof Select;

const RoleSelect = ({ superUser, disabled }: RoleSelectProps) => {
  const [role, setRole] = useSyncedState(superUser.role);
  const [saving, setSaving] = useState(false);

  const handleChange = (e: SelectChangeEvent<SuperUserRole>) => {
    setRole(e.target.value as SuperUserRole);
  };

  const saveRoleChange = async () => {
    if (role === superUser.role) {
      return;
    }

    setSaving(true);

    await api.put(`/users/${superUser.id}/role`, {
      role,
    });

    setSaving(false);
  };

  return (
    <>
      <StyledSelect
        value={role}
        onChange={handleChange}
        size="small"
        disabled={disabled || saving}
        MenuProps={{
          TransitionProps: {
            onExit: saveRoleChange,
          },
        }}
      >
        <MenuItem value={SuperUserRole.LEIDEN_USER}>Leiden user</MenuItem>
        <MenuItem value={SuperUserRole.EGODACT_USER}>Egodact user</MenuItem>
      </StyledSelect>
    </>
  );
};

export default RoleSelect;
