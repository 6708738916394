import React, { useState } from 'react';
import PushToRight from '@/components/PushToRight';
import { TextField } from '@mui/material';
import Space from '@/components/Space';
import validator from 'validator';
import ApiError from '@/lib/api/ApiError';
import api from '@/lib/api';
import { LoadingButton } from '@mui/lab';

type OtpFormProps = {
  email: string;
  password: string;
  onCompleteLogin: (accessToken: string) => void;
};

type SignInResponse = {
  accessToken: string;
};

const OtpForm = ({ email, password, onCompleteLogin }: OtpFormProps) => {
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setOtpError(null);

    if (otp.length === 0) {
      setOtpError('Please enter your One-Time Password');

      return;
    }

    if (!validator.isInt(otp)) {
      setOtpError('Please enter a valid One-Time Password');

      return;
    }

    setLoading(true);

    try {
      const { accessToken } = await api.post<SignInResponse>('/auth/sign-in', {
        email,
        password,
        otp,
      });

      onCompleteLogin(accessToken);
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setOtpError(error.getErrorCode());
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1>Enter your One-Time Password</h1>
      <p>Enter the One-Time Password displayed in your Authenticator app.</p>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="filled"
          name="otp"
          label="One-Time Password"
          value={otp}
          onChange={e => setOtp(e.target.value)}
          error={otpError !== null}
          helperText={otpError}
          type="number"
          autoFocus
          fullWidth
        />
        <Space height={12} />
        <PushToRight>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            disableElevation
          >
            Sign in
          </LoadingButton>
        </PushToRight>
      </form>
    </>
  );
};

export default OtpForm;
