import { Perspective } from '@/routes/perspectives/types';
import { useState, useEffect } from 'react';
import keyElementsById from '@/lib/keyElementsById';

export type RootColumn = {
  perspectiveId: number;
  perspectiveIndex: number;
  isRoot: true;
};

export type Column = {
  parentSublevelId: number;
  parentSublevelIndex: number;
  isRoot: false;
};

const MISSING_COLUMN: Column = {
  parentSublevelId: -1,
  parentSublevelIndex: -1,
  isRoot: false,
} as const;

type ColumnsArray = [RootColumn, ...Column[]];

const useColumns = (
  perspective: Perspective,
  perspectiveIndex: number,
): [
  ColumnsArray,
  (id: number, index: number, nestingIndex: number) => void,
] => {
  const [columns, setColumns] = useState<ColumnsArray>([
    {
      perspectiveId: perspective.id,
      perspectiveIndex,
      isRoot: true,
    },
  ]);

  useEffect(() => {
    setColumns([
      {
        perspectiveId: perspective.id,
        perspectiveIndex,
        isRoot: true,
      },
    ]);
  }, [perspective.id, perspectiveIndex]);

  useEffect(() => {
    const sublevelsById = keyElementsById(perspective.sublevels);

    setColumns((columns: ColumnsArray): ColumnsArray => {
      const mappedColumns = columns.map(column => {
        if (column.isRoot) {
          return column;
        }

        if (typeof sublevelsById[column.parentSublevelId] === 'undefined') {
          return MISSING_COLUMN;
        }

        return column;
      });

      if (!mappedColumns.includes(MISSING_COLUMN)) {
        return columns;
      }

      return mappedColumns.slice(
        0,
        mappedColumns.indexOf(MISSING_COLUMN),
      ) as ColumnsArray;
    });
  }, [perspective.sublevels]);

  const openSublevel = (
    id: number,
    index: number,
    nestingIndex: number,
  ): void => {
    setColumns(columns => {
      const targetColumn = columns[nestingIndex + 1] as Column | undefined;
      const sublevelAlreadyOpen = targetColumn?.parentSublevelId === id;

      if (sublevelAlreadyOpen) {
        return columns.slice(0, nestingIndex + 1) as ColumnsArray;
      }

      return columns.slice(0, nestingIndex + 1).concat({
        parentSublevelId: id,
        parentSublevelIndex: index,
        isRoot: false,
      }) as ColumnsArray;
    });
  };

  return [columns, openSublevel];
};

export default useColumns;
